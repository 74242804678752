import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import qs from "qs";

import { logout } from "./../../store/ducks/auth";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: null };
  }

  logout = async e => {
    e.preventDefault();
    await this.props.logout();
  };

  onSearchChange = event => {
    this.setState({ search: event.target.value });
  };

  onSearchSubmit = event => {
    event.preventDefault();
    this.props.history.push(
      `${this.props.location.pathname}?search=${this.state.search || ""}`
    );
  };

  render() {
    const { breadcrumbs, location } = this.props;
    let searchValue = qs.parse(location.search.substr(1));
    const user = this.props.user;

    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            {breadcrumbs ? (
              <div className="form-header">
                <Link
                  to={`/admin/${breadcrumbs[0].to}`}
                  className="breadcrumb-back"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                <Breadcrumb>
                  {breadcrumbs.map((breadcrumb, i) => {
                    if (breadcrumb.to) {
                      return (
                        <BreadcrumbItem key={i}>
                          <Link to={`/admin/${breadcrumb.to}`}>
                            {breadcrumb.title}
                          </Link>
                        </BreadcrumbItem>
                      );
                    } else {
                      return (
                        <BreadcrumbItem active key={i}>
                          {breadcrumb.title}
                        </BreadcrumbItem>
                      );
                    }
                  })}
                </Breadcrumb>
              </div>
            ) : (
              <Form
                className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex"
                onSubmit={this.onSearchSubmit}
              >
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="search"
                      onChange={this.onSearchChange}
                      placeholder="Buscar..."
                      type="text"
                      defaultValue={searchValue.search || ""}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            )}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <i className="fa fa-user"></i>
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {`${user.name} ${user.last_name || ""}`}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bem-vindo!</h6>
                  </DropdownItem>
                  <DropdownItem href="#" onClick={this.logout}>
                    <i className="ni ni-user-run" />
                    <span>Sair</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = ({ common, auth }) => ({
  breadcrumbs: common.breadcrumbs,
  user: auth.user
});

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
