import memoize from "memoize-one";
import React from "react";

import FormMasked from "./FormMasked";

const mask = memoize(rawValue => {
  rawValue = rawValue.replace(/[^0-9-]+/g, "");
  let mask = rawValue.split("").map(() => /[-\d]/);

  if (mask.length === 0) {
    mask.push(/[-\d]/);
  }

  return mask.filter(Boolean);
});

function FormInputNumber(props) {
  return <FormMasked noPadding={true} {...props} mask={mask} />;
}

export default FormInputNumber;
