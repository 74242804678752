import memoize from "memoize-one";
import React from "react";

import FormMasked from "./FormMasked";

const mask = memoize(rawValue => {
  const { length } = (rawValue || "").replace(/[^\d]/g, "") || "";

  return [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    length >= 11 ? /\d/ : undefined,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ].filter(Boolean);
});

function FormInputPhone(props) {
  return <FormMasked noPadding={true} {...props} mask={mask} />;
}

export default FormInputPhone;
