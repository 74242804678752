import Index from "./views/Index";
import Login from "./views/Login";
import RecoverPassword from "./views/RecoverPassword";

import ClientsList from "./views/clients/List";
import ClientsProfile from "./views/clients/Profile";
import ClientsRecoverPassword from "./views/clients/ClientsRecoverPassword";

import ClimatesForm from "./views/climates/Form";
import ClimatesList from "./views/climates/List";

import ClothingCategoriesForm from "./views/clothingCategories/Form";
import ClothingCategoriesList from "./views/clothingCategories/List";

import ClothingColorsForm from "./views/clothingColors/Form";
import ClothingColorsList from "./views/clothingColors/List";

import ClothingStylesForm from "./views/clothingStyles/Form";
import ClothingStylesList from "./views/clothingStyles/List";

import GastronomyTypesForm from "./views/gastronomyTypes/Form";
import GastronomyTypesList from "./views/gastronomyTypes/List";

import GenresForm from "./views/genres/Form";
import GenresList from "./views/genres/List";

import NewsCategoriesForm from "./views/newsCategories/Form";
import NewsCategoriesList from "./views/newsCategories/List";

import NotifificationsForm from "./views/notifications/Form";

import RecipesForm from "./views/recipes/Form";
import RecipesList from "./views/recipes/List";

import UsersList from "./views/users/List";
import UsersForm from "./views/users/Form";
import UsersPasswordForm from "./views/users/PasswordForm";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    visible: false,
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2",
    component: Index,
    layout: "/admin",
    visible: true,
  },

  {
    path: "/recuperar-senha",
    name: "Recuperar Senha",
    icon: "ni ni-key-25 text-info",
    component: RecoverPassword,
    layout: "/auth",
    visible: false,
  },

  {
    path: "/clientes/recuperar-senha",
    name: "Recuperar Senha",
    icon: "ni ni-key-25 text-info",
    component: ClientsRecoverPassword,
    layout: "/auth",
    visible: false,
  },

  {
    path: "/looks/climas/form",
    name: "Climas",
    component: ClimatesForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/looks/climas",
    name: "Climas",
    icon: "fas fa-cloud-sun-rain",
    component: ClimatesList,
    layout: "/admin",
    group: "Looks",
    visible: true,
  },

  {
    path: "/looks/categorias/form",
    name: "Categorias",
    component: ClothingCategoriesForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/looks/categorias",
    name: "Categorias",
    icon: "fas fa-tshirt",
    component: ClothingCategoriesList,
    layout: "/admin",
    group: "Looks",
    visible: true,
  },

  {
    path: "/looks/cores/form",
    name: "Cores",
    component: ClothingColorsForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/looks/cores",
    name: "Cores",
    icon: "fas fa-palette",
    component: ClothingColorsList,
    layout: "/admin",
    group: "Looks",
    visible: true,
  },

  {
    path: "/looks/estilos/form",
    name: "Estilos",
    component: ClothingStylesForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/looks/estilos",
    name: "Estilos",
    icon: "fas fa-hat-cowboy",
    component: ClothingStylesList,
    layout: "/admin",
    group: "Looks",
    visible: true,
  },

  {
    path: "/filmes/generos/form",
    name: "Gêneros",
    component: GenresForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/filmes/generos",
    name: "Gêneros",
    icon: "fas fa-film",
    component: GenresList,
    layout: "/admin",
    group: "Filmes",
    visible: true,
  },

  {
    path: "/noticias/categorias/form",
    name: "Categorias",
    component: NewsCategoriesForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/noticias/categorias",
    name: "Categorias",
    icon: "fas fa-newspaper",
    component: NewsCategoriesList,
    layout: "/admin",
    group: "Notícias",
    visible: true,
  },

  {
    path: "/gastronomia/categorias/form",
    name: "Categorias Culinárias",
    component: GastronomyTypesForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/gastronomia/categorias",
    name: "Categorias Culinárias",
    icon: "fas fa-utensils",
    component: GastronomyTypesList,
    layout: "/admin",
    group: "Culinária",
    visible: true,
  },

  {
    path: "/gastronomia/receitas/form",
    name: "Receitas",
    component: RecipesForm,
    layout: "/admin",
    visible: false,
    roles: ["admin"],
  },
  {
    path: "/gastronomia/receitas",
    name: "Receitas",
    icon: "fas fa-pizza-slice",
    component: RecipesList,
    layout: "/admin",
    group: "Culinária",
    visible: true,
  },

  {
    path: "/notificacoes",
    name: "Enviar notificação",
    icon: "fas fa-bell",
    component: NotifificationsForm,
    group: "Administração",
    layout: "/admin",
    visible: true,
  },

  {
    path: "/clientes/perfil",
    name: "Clientes",
    component: ClientsProfile,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: "ni ni-single-02",
    component: ClientsList,
    group: "Administração",
    layout: "/admin",
    visible: true,
  },

  {
    path: "/usuarios/senha",
    name: "Usuários",
    component: UsersPasswordForm,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/usuarios/form",
    name: "Usuários",
    component: UsersForm,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/usuarios",
    name: "Usuários",
    icon: "ni ni-single-02",
    component: UsersList,
    group: "Administração",
    layout: "/admin",
    visible: true,
  },
];
export default routes;
