import qs from "qs";

// Action Types
export const REPORTS = "bontempo/cities/REPORTS";
export const REPORTS_SUCCESS = "bontempo/cities/REPORTS_SUCCESS";
export const REPORTS_FAIL = "bontempo/cities/REPORTS_FAIL";

export const CLIENTS_LOCATIONS = "bontempo/cities/CLIENTS_LOCATIONS";
export const CLIENTS_LOCATIONS_SUCCESS =
    "bontempo/cities/CLIENTS_LOCATIONS_SUCCESS";
export const CLIENTS_LOCATIONS_FAIL = "bontempo/cities/CLIENTS_LOCATIONS_FAIL";

export const GRAPHICS = "bontempo/cities/GRAPHICS";
export const GRAPHICS_SUCCESS = "bontempo/cities/GRAPHICS_SUCCESS";
export const GRAPHICS_FAIL = "bontempo/cities/GRAPHICS_FAIL";

export const GRAPHICS_FILTERS = "bontempo/cities/GRAPHICS_FILTERS";
export const GRAPHICS_FILTERS_SUCCESS =
    "bontempo/cities/GRAPHICS_FILTERS_SUCCESS";
export const GRAPHICS_FILTERS_FAIL = "bontempo/cities/GRAPHICS_FILTERS_FAIL";

// Reducer
const initialState = {
    clientsLocations: null,
    graphics: null,
    graphicsFilters: null,
    loadingReports: false,
    loadingClientsLocations: false,
    loadingGraphics: false,
    reports: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REPORTS:
            return {...state, loadingReports: true };
        case REPORTS_SUCCESS:
            return {
                ...state,
                reports: action.payload.data,
                loadingReports: false
            };
        case REPORTS_FAIL:
            return {
                ...state,
                loadingReports: false,
                error: "Não foi possível completar a requisição."
            };

        case CLIENTS_LOCATIONS:
            return {...state, loadingClientsLocations: true };
        case CLIENTS_LOCATIONS_SUCCESS:
            return {
                ...state,
                clientsLocations: action.payload.data,
                loadingClientsLocations: false
            };
        case CLIENTS_LOCATIONS_FAIL:
            return {
                ...state,
                loadingClientsLocations: false,
                error: "Não foi possível completar a requisição."
            };

        case GRAPHICS:
            return {...state, loadingGraphics: true };
        case GRAPHICS_SUCCESS:
            return {
                ...state,
                graphics: action.payload.data,
                loadingGraphics: false
            };
        case GRAPHICS_FAIL:
            return {
                ...state,
                loadingGraphics: false,
                error: "Não foi possível completar a requisição."
            };

        case GRAPHICS_FILTERS:
            return {...state };
        case GRAPHICS_FILTERS_SUCCESS:
            return {
                ...state,
                graphicsFilters: action.payload.data
            };
        case GRAPHICS_FILTERS_FAIL:
            return {
                ...state,
                error: "Não foi possível completar a requisição."
            };

        default:
            return state;
    }
}

// Action Creators
export function getReports() {
    return {
        type: REPORTS,
        payload: {
            request: {
                url: "/reports"
            }
        }
    };
}

export function getClientsLocations(hasFurniture) {
    return {
        type: CLIENTS_LOCATIONS,
        payload: {
            request: {
                url: "/reports/clients-locations" +
                    (hasFurniture ? "?has_furniture=1" : "")
            }
        }
    };
}

export function getGraphics(params) {
    return {
        type: GRAPHICS,
        payload: {
            request: {
                url: `/reports/graphics?${qs.stringify(params)}`
            }
        }
    };
}

export function getGraphicsFilters() {
    return {
        type: GRAPHICS_FILTERS,
        payload: {
            request: {
                url: `/reports/graphics/filters`
            }
        }
    };
}