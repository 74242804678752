import React from "react";
import _Form from "./../_Form";
import { Card, CardBody, Container, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import { add, edit, get, getDetails } from "./../../store/ducks/newsCategories";

import { Form, FormInput, FormSelect, Header } from "./../../components";

import Yup from "../../utils/yup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required()
});

class NewsCategoriesForm extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      breadcrumbs: [
        {
          title: "Gêneros",
          to: this.props.module
        }
      ],
      id: false
    };
  }

  render() {
    const { breadcrumbs, fields } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={fields}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validationSchema}
                    render={formProps => (
                      <>
                        <Row>
                          <FormInput
                            label="Título"
                            name="title"
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                          <FormSelect
                            label="Status"
                            name="status"
                            options={[
                              { label: "Ativo", value: 1 },
                              { label: "Inativo", value: 0 }
                            ]}
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                        </Row>
                      </>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, newsCategories }) => ({
  details: newsCategories.details,
  loadingBar: common.loadingBar,
  module: newsCategories.module
});

const mapDispatchToProps = {
  add,
  edit,
  get,
  getDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsCategoriesForm);
