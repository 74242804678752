// Action Types
export const UPLOAD_SINGLE = "bontempo/files/UPLOAD_SINGLE";
export const UPLOAD_SINGLE_SUCCESS = "bontempo/files/UPLOAD_SINGLE_SUCCESS";
export const UPLOAD_SINGLE_FAIL = "bontempo/files/UPLOAD_SINGLE_FAIL";

// Reducer
const initialState = {
  loading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_SINGLE:
      return { ...state, loading: true };
    case UPLOAD_SINGLE_SUCCESS:
      return { ...state, loading: false };
    case UPLOAD_SINGLE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

// Action Creators
export function uploadSingle(file, folder) {
  let data = new FormData();
  data.append("file", file);
  data.append("folder", folder);

  return {
    type: UPLOAD_SINGLE,
    payload: {
      request: {
        url: `/files/single`,
        method: "post",
        data: data,
        headers: { "Content-Type": "multipart/form-data" }
      }
    }
  };
}
