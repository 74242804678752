import React from "react";

import { Col } from "reactstrap";

const ReportNumber = props => (
  <Col
    xs={props.x || 6}
    sm={props.sm || 3}
    className={props.className || "mb-4"}
  >
    <div
      className="bg-secondary p-3 rounded d-flex justify-content-start align-items-center"
      style={{ height: "100%" }}
    >
      <i className={props.icon} style={{ color: props.color, fontSize: 45 }} />
      <div className="pl-3">
        <small>{props.title}</small>
        <br />
        <strong>{props.number}</strong>
        <br />
        <small>{props.observations}</small>
      </div>
    </div>
  </Col>
);

export default ReportNumber;
