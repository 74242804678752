// Action Types
export const FORGOT = "bontempo/auth/FORGOT";
export const FORGOT_SUCCESS = "bontempo/auth/FORGOT_SUCCESS";
export const FORGOT_FAIL = "bontempo/auth/FORGOT_FAIL";

export const LOGIN = "bontempo/auth/LOGIN";
export const LOGIN_SUCCESS = "bontempo/auth/LOGIN_SUCCESS";
export const LOGIN_FAIL = "bontempo/auth/LOGIN_FAIL";

export const VALIDATE_RECOVERY_TOKEN = "bontempo/auth/VALIDATE_RECOVERY_TOKEN";
export const VALIDATE_RECOVERY_TOKEN_SUCCESS =
  "bontempo/auth/VALIDATE_RECOVERY_TOKEN_SUCCESS";
export const VALIDATE_RECOVERY_TOKEN_FAIL =
  "bontempo/auth/VALIDATE_RECOVERY_TOKEN_FAIL";

export const UPDATE_PASSWORD = "bontempo/auth/UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "bontempo/auth/UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "bontempo/auth/UPDATE_PASSWORD_FAIL";

export const LOGOUT = "bontempo/auth/LOGOUT";

// Reducer
const initialState = {
  loading: false,
  user: null,
  jwt: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        jwt: action.payload.data.token,
        user: action.payload.data.user
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };

    case LOGOUT:
      return {
        ...state,
        ...initialState
      };

    default:
      return state;
  }
}

// Action Creators

export function login(data) {
  return {
    type: LOGIN,
    payload: {
      request: {
        url: `/users/auth/login`,
        method: "post",
        data: data
      }
    }
  };
}
export function forgot(data) {
  return {
    type: FORGOT,
    payload: {
      request: {
        url: `/users/auth/retrieve-password`,
        method: "post",
        data: data
      }
    }
  };
}

export function validateRecoveryToken(recovery_token) {
  return {
    type: VALIDATE_RECOVERY_TOKEN,
    payload: {
      request: {
        url: `/users/auth/check-hash/${recovery_token}`
      }
    }
  };
}

export function updatePassword(data) {
  return {
    type: UPDATE_PASSWORD,
    payload: {
      request: {
        url: `/users/auth/new-password`,
        method: "put",
        data: data
      }
    }
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}
