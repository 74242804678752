import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import FormField from "./FormField";

const styles = {
  control: styles => ({
    ...styles,
    backgroundColor: "white"
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? "#38A95E"
      : isFocused
      ? "#38A996"
      : null,
    color: isDisabled ? "#ccc" : isSelected || isFocused ? "white" : "#8898aa",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled && "#38A95E",
      color: "white"
    }
  })
};

function FormSelectComponent({ field, form, options, ...props }) {
  const [innerValue, setInnerValue] = useState(field.value);
  const fieldName = props.property || field.name;

  useEffect(() => {
    if (props.async) {
      setInnerValue(field.value);
    } else {
      if (options && options.length) {
        if (props.isMulti === true) {
          setInnerValue(props.findDefaultValues(field.value));
        } else {
          setInnerValue(options.find(option => option.value === field.value));
        }
      }
    }
  }, [field.value, options]);

  function innerOnChange(selected) {
    if (selected === null || selected.value === undefined) {
      if (!selected) {
        setInnerValue([]);
        form.setFieldValue(field.name, []);
      } else {
        // multi option
        let selectedOptions;
        if (props.async) {
          selectedOptions = selected;
        } else {
          selectedOptions = selected.map(opt => {
            return opt.value;
          });
        }
        setInnerValue(selectedOptions);
        form.setFieldValue(field.name, selectedOptions);
      }
    } else {
      setInnerValue(selected);

      if (props.valueAsObject === true) {
        form.setFieldValue(field.name, selected);
      } else {
        form.setFieldValue(field.name, selected.value);
      }
    }

    if (props.onChange) {
      props.onChange(selected);
    }
  }

  return props.async ? (
    <AsyncSelect
      classNamePrefix="select-form"
      className={`form-select ${
        _.get(form.touched, fieldName) && _.get(form.errors, fieldName)
          ? "error"
          : null
      }`}
      styles={styles}
      {...props}
      onBlur={() => form.setFieldTouched(field.name, true)}
      onChange={innerOnChange}
      options={options}
      noOptionsMessage={
        props.noOptionsMessage
          ? props.noOptionsMessage
          : () => "Busque pelos produtos desejados."
      }
      placeholder={props.placeholder || "Selecione..."}
      value={innerValue}
    />
  ) : (
    <ReactSelect
      classNamePrefix="select-form"
      className={`form-select ${
        _.get(form.touched, fieldName) && _.get(form.errors, fieldName)
          ? "error"
          : null
      }`}
      styles={styles}
      {...props}
      onBlur={() => form.setFieldTouched(field.name, true)}
      onChange={innerOnChange}
      options={options}
      noOptionsMessage={() => "Nenhuma opção encontrada."}
      placeholder={props.placeholder || "Selecione..."}
      value={innerValue}
    />
  );
}

export default function FormSelect(props) {
  return <FormField {...props} component={FormSelectComponent} />;
}
