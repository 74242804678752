import { Component } from "react";
import qs from "qs";

export default class _List extends Component {
  async componentDidMount() {
    await this.load();
  }

  async shouldComponentUpdate(prevProps) {
    const shouldUpdate =
      prevProps.location.search !== this.props.location.search;
    if (shouldUpdate) {
      await this.load();
    }
    return shouldUpdate;
  }

  load = async () => {
    const { loadingBar } = this.props;
    const params = qs.parse(window.location.search.substr(1));

    if (loadingBar) {
      loadingBar.staticStart(35);
    }

    await this.props
      .get({ page: params.page, search: { search: params.search } })
      .then(() => {
        if (loadingBar) {
          loadingBar.complete();
        }
      });
  };
}
