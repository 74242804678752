import React from "react";
import _Form from "./../_Form";
import { Card, Container, Col, Row, CardBody } from "reactstrap";

import { connect } from "react-redux";
import { add, edit, getDetails } from "./../../store/ducks/users";

import { Form, Header } from "./../../components";

import UsersFormFields from "./UsersFormFields";

import Yup from "../../utils/yup";

class UsersForm extends _Form {
  validationSchema = {
    name: Yup.string()
      .nullable()
      .required(),
    email: Yup.string()
      .trim()
      .email()
      .nullable()
      .required()
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      breadcrumbs: [
        {
          title: "Usuários",
          to: this.props.module
        }
      ],
      fields: {},
      id: false
    };
  }

  componentWillMount() {
    if (this.props.location.pathname === this.props.match.path) {
      this.validationSchema.password = Yup.string()
        .nullable()
        .required()
        .min(8, "A senha deve ter pelo menos 8 caracteres")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9])/,
          "A senha deve conter letras e números."
        );
      this.validationSchema.repeat_password = Yup.string()
        .nullable()
        .required()
        .test("password-confirm", "As senhas não coincidem", function(value) {
          return this.parent.password === value;
        });
    }

    this.validationSchema = Yup.object().shape(this.validationSchema);
  }

  render() {
    const { breadcrumbs, fields, mode } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={fields}
                    onSubmit={this.onFormSubmit}
                    validationSchema={this.validationSchema}
                    render={formProps => <UsersFormFields mode={mode} />}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, users }) => ({
  details: users.details,
  loadingBar: common.loadingBar,
  module: users.module
});

const mapDispatchToProps = {
  add,
  edit,
  getDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersForm);
