import React from "react";
import { toast } from "react-toastify";
import { store } from "./../store";
import { setBreadcrumbs } from "./../store/ducks/common";

export default class _Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "add" // add ou update
    };
  }

  async componentDidMount() {
    let { fields, id } = this.state;
    const { getDetails, loadingBar, location, match } = this.props;

    // verifica se é a página de edição ou cadastro
    if (location.pathname !== match.path) {
      this.setState({ mode: "update" });

      if (loadingBar) {
        loadingBar.staticStart(35);
      }
      // separa o ID da URL pra buscar os dados do usuário
      id = parseInt(location.pathname.split("/").pop());
      await getDetails(id);
      fields = this.props.details;
      if (loadingBar) {
        loadingBar.complete();
      }
    } else {
      // no cadastro volta o state pro valor inicial
      id = false;
      fields = {};
    }

    this.setState({ id, fields });

    let title = "Incluir";
    if (location.pathname !== match.path) {
      title = "Edição";
    }
    this.setState({
      breadcrumbs: [...this.state.breadcrumbs, { title: title, to: null }]
    });
    store.dispatch(setBreadcrumbs(this.state.breadcrumbs));
  }

  componentWillUnmount() {
    store.dispatch(setBreadcrumbs(null));
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  // callback para o envio do formulário
  onFormSubmit = async values => {
    let response;

    if (this.state.id) {
      response = await this.props.edit(values, this.state.id);
    } else {
      response = await this.props.add(values);
    }

    let message = this.state.id
        ? "Registro atualizado com sucesso!"
        : "Registro cadastrado com sucesso!",
      error = true;

    if (!response.error) {
      error = false;
      this.props.history.push(`/admin/${this.props.module || "dashboard"}`);
      toast(message, {
        className: "bg-success",
        progressClassName: "bg-white",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success"
      });
    }

    return error;
  };
}
