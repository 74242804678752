import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Nav,
  NavItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Spinner
} from "reactstrap";
import confirmAlert from "./../ConfirmAlert";
import { connect } from "react-redux";
import qs from "qs";
import _ from "lodash";
import { toast } from "react-toastify";

class ContentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDeleting: [] };
  }

  onDelete = async row => {
    confirmAlert(
      "Tem certeza que deseja excluir este registro?",
      "Esta ação não poderá ser revertida.",
      async () => {
        let isDeletingState = this.state.isDeleting;
        isDeletingState[row.id] = true;
        this.setState({ isDeleting: isDeletingState });

        await this.props.onDelete(row.id).then(data => {
          const hasDeleted = data.type.match(/SUCCESS/i);
          toast(
            hasDeleted
              ? "Registro excluído com sucesso."
              : "Não foi possível excluir.",
            {
              className: hasDeleted ? "bg-success" : "bg-error",
              progressClassName: "bg-white",
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              type: hasDeleted ? "success" : "error"
            }
          );
          if (hasDeleted) {
            row.deleted = true;
          }
        });

        delete isDeletingState[row.id];
        this.setState({ isDeleting: isDeletingState });
      }
    );
  };

  getPages = () => {
    const params = qs.parse(window.location.search.substr(1));
    const currentPage = params.page ? parseInt(params.page) : 1;

    let pages = [];
    pages[currentPage] = currentPage;

    if (currentPage > 1) {
      for (let i = currentPage - 1; i >= currentPage - 3 && i > 0; i--) {
        pages[i] = i;
      }
    }

    if (currentPage < this.props.data.lastPage) {
      for (
        let i = currentPage + 1;
        i < currentPage + 4 && i <= this.props.data.lastPage;
        i++
      ) {
        pages.push(i);
      }
    }

    return pages;
  };

  render() {
    if (!this.props.data) {
      return null;
    }

    let params = qs.parse(window.location.search.substr(1));

    const {
        columns,
        currentRoute,
        customButtons,
        data,
        hasEdit,
        hasInsert,
        headerExtraButtons,
        loading,
        middleware,
        onDelete
      } = this.props,
      currentPage = params.page ? parseInt(params.page) : 1;

    delete params.page;
    params = params ? `&${qs.stringify(params)}` : "";

    return (
      <>
        <Card>
          {this.props.hideHeader !== true ? (
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col pl-1">
                  <h3 className="mb-0">{currentRoute.name}</h3>
                </div>
                <div className="col text-right pr-1">
                  {headerExtraButtons}
                  {hasInsert === true && (
                    <Link
                      to={currentRoute.layout + currentRoute.path + `/form`}
                    >
                      <Button
                        className="add-button"
                        color="primary"
                        to={currentRoute.path + "/form"}
                        size="md"
                      >
                        <i className="ni ni-fat-add" />
                        Incluir
                      </Button>
                    </Link>
                  )}
                </div>
              </Row>
            </CardHeader>
          ) : null}
          {(this.state.isDeleting.length || !loading) && (
            <>
              {data.data && data.data.length ? (
                <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        {columns.map(column => (
                          <th
                            key={column.slug}
                            scope="col"
                            className={`${
                              column.align === "center" ? "text-center" : ""
                            }`}
                          >
                            {column.title}
                          </th>
                        ))}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.data.map(row => {
                        if (row.deleted === true) {
                          return null;
                        }
                        if (middleware) {
                          row = middleware(row);
                        }

                        return (
                          <tr key={row.id}>
                            {columns.map(column => (
                              <td
                                key={column.slug + row.id}
                                className={`${
                                  column.align === "center" ? "text-center" : ""
                                }`}
                              >
                                {_.get(row, column.slug) || "-"}
                              </td>
                            ))}
                            <td className="text-right">
                              {this.state.isDeleting[row.id] === true ? (
                                <Spinner
                                  type="grow"
                                  color="primary"
                                  size="sm"
                                />
                              ) : (
                                <Nav className="justify-content-end">
                                  {customButtons ? customButtons(row) : null}
                                  {hasEdit === true || hasEdit === undefined ? (
                                    <NavItem className="pl-2">
                                      <Link
                                        to={
                                          currentRoute.layout +
                                          currentRoute.path +
                                          `/form/${row.id}`
                                        }
                                      >
                                        <Button
                                          className="mb-1"
                                          color="default"
                                          size="sm"
                                          type="button"
                                        >
                                          <i className="fas fa-edit" /> Editar
                                        </Button>
                                      </Link>
                                    </NavItem>
                                  ) : null}
                                  {onDelete && (
                                    <NavItem className="pl-2">
                                      <Button
                                        className="mb-1"
                                        color="default"
                                        onClick={() => this.onDelete(row)}
                                        size="sm"
                                        type="button"
                                      >
                                        <i className="far fa-trash-alt" />{" "}
                                        Excluir
                                      </Button>
                                    </NavItem>
                                  )}
                                </Nav>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {/* Paginação */}
                  {data.total > data.perPage && (
                    <CardFooter className="py-4">
                      <nav aria-label="...">
                        <Pagination
                          className="pagination justify-content-end mb-0"
                          listClassName="justify-content-end mb-0"
                        >
                          {currentPage > 1 && (
                            <PaginationItem>
                              <PaginationLink
                                tag={NavLinkRRD}
                                to={
                                  currentRoute.layout +
                                  currentRoute.path +
                                  "?page=" +
                                  (currentPage - 1) +
                                  params
                                }
                              >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                              </PaginationLink>
                            </PaginationItem>
                          )}
                          {this.getPages().map((el, i) => (
                            <PaginationItem
                              className={currentPage === i ? "active" : ""}
                              key={`pagination-${i}`}
                            >
                              <PaginationLink
                                tag={NavLinkRRD}
                                to={
                                  currentRoute.layout +
                                  currentRoute.path +
                                  "?page=" +
                                  i +
                                  params
                                }
                              >
                                {i}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          {currentPage < data.lastPage && (
                            <PaginationItem>
                              <PaginationLink
                                tag={NavLinkRRD}
                                to={
                                  currentRoute.layout +
                                  currentRoute.path +
                                  "?page=" +
                                  (currentPage + 1) +
                                  params
                                }
                              >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                              </PaginationLink>
                            </PaginationItem>
                          )}
                        </Pagination>
                      </nav>
                    </CardFooter>
                  )}
                </>
              ) : (
                <div className="p-4 text-muted">
                  Nenhum resultado encontrado.
                </div>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

const mapStateToProps = ({ common }) => ({
  currentRoute: common.currentRoute
});

export default connect(mapStateToProps)(ContentTable);
