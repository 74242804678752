import React from "react";
import { Container } from "reactstrap";
import _List from "./../_List";

import { connect } from "react-redux";
import { get, remove } from "./../../store/ducks/clothingStyles";

import { ContentTable, Header } from "./../../components";

class ClothingStylesList extends _List {
  render() {
    const { data } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--7 bg-default" fluid>
          <ContentTable
            columns={[
              {
                title: "Título",
                slug: "title"
              }
            ]}
            data={data}
            hasInsert={true}
            onDelete={this.props.remove}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, clothingStyles }) => ({
  currentRoute: common.currentRoute,
  data: clothingStyles.data,
  loading: clothingStyles.loading,
  loadingBar: common.loadingBar
});

const mapDispatchToProps = {
  get,
  remove
};

export default connect(mapStateToProps, mapDispatchToProps)(ClothingStylesList);
