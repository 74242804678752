import memoize from "memoize-one";
import React from "react";

import FormMasked from "./FormMasked";

const mask = memoize(rawValue => {
  return [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/].filter(Boolean);
});

function FormInputZipcode(props) {
  return <FormMasked noPadding={true} {...props} mask={mask} />;
}

export default FormInputZipcode;
