import React from "react";
import { Button, Container, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import _List from "./../_List";

import { connect } from "react-redux";
import { get } from "./../../store/ducks/clients";

import { ContentTable, Header } from "./../../components";

class ClientsList extends _List {
  render() {
    const { data, loading } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--7 bg-default" fluid>
          <ContentTable
            columns={[
              {
                title: "#",
                slug: "avatar_path"
              },
              {
                title: "Nome",
                slug: "name"
              },
              {
                title: "E-mail",
                slug: "email"
              },
              {
                title: "Telefone",
                slug: "phone"
              }
            ]}
            customButtons={row => (
              <>
                <NavItem className="pl-2">
                  <Link to={"/admin/clientes/perfil/" + row.id}>
                    <Button color="default" size="sm" type="button">
                      <i className="fa fa-user-circle" /> Perfil
                    </Button>
                  </Link>
                </NavItem>
              </>
            )}
            data={data}
            hasEdit={false}
            hasInsert={false}
            loading={loading}
            middleware={row => {
              row.avatar_path = row.avatar ? (
                <div
                  style={{
                    height: 50,
                    width: 50,
                    backgroundImage: `url(${row.avatar ? row.avatar.url : ""})`,
                    backgroundSize: "cover"
                  }}
                  className="rounded-circle"
                />
              ) : (
                <i
                  className="fas fa-user-circle text-muted"
                  style={{ fontSize: 50 }}
                />
              );

              return row;
            }}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, clients }) => ({
  currentRoute: common.currentRoute,
  data: clients.data,
  loading: clients.loading,
  loadingBar: common.loadingBar
});

const mapDispatchToProps = {
  get
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
