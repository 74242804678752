import { confirmAlert as reactConfirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const confirmAlert = (title, text, confirm) => {
  reactConfirmAlert({
    title: title,
    message: text || "",
    buttons: [
      {
        label: "Sim",
        onClick: confirm
      },
      {
        label: "Não",
        onClick: () => {}
      }
    ]
  });
};

export default confirmAlert;
