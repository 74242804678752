import qs from "qs";

// Action Types
export const LIST = "bipp/clients/LIST";
export const LIST_SUCCESS = "bipp/clients/LIST_SUCCESS";
export const LIST_FAIL = "bipp/clients/LIST_FAIL";

export const DETAILS = "bipp/clients/DETAILS";
export const DETAILS_SUCCESS = "bipp/clients/DETAILS_SUCCESS";
export const DETAILS_FAIL = "bipp/clients/DETAILS_FAIL";

export const SAVE = "bipp/clients/SAVE";
export const SAVE_SUCCESS = "bipp/clients/SAVE_SUCCESS";
export const SAVE_FAIL = "bipp/clients/SAVE_FAIL";

export const DELETE = "bipp/clients/DELETE";
export const DELETE_SUCCESS = "bipp/clients/DELETE_SUCCESS";
export const DELETE_FAIL = "bipp/clients/DELETE_FAIL";

export const VALIDATE_RECOVERY_TOKEN = "bontempo/auth/VALIDATE_RECOVERY_TOKEN";
export const VALIDATE_RECOVERY_TOKEN_SUCCESS =
    "bontempo/auth/VALIDATE_RECOVERY_TOKEN_SUCCESS";
export const VALIDATE_RECOVERY_TOKEN_FAIL =
    "bontempo/auth/VALIDATE_RECOVERY_TOKEN_FAIL";

export const UPDATE_PASSWORD = "bontempo/auth/UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "bontempo/auth/UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "bontempo/auth/UPDATE_PASSWORD_FAIL";

// Reducer
const initialState = {
    data: null,
    details: null,
    loading: false,
    module: "clientes",
    total: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST:
            return {...state, loading: true };
        case LIST_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };
        case LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: "Não foi possível completar a requisição.",
            };

        case DETAILS:
            return {...state, loading: true };
        case DETAILS_SUCCESS:
            return {
                ...state,
                details: action.payload.data,
                loading: false,
            };
        case DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: "Não foi possível completar a requisição.",
            };

        case SAVE:
            return {...state, loading: true };
        case SAVE_SUCCESS:
        case SAVE_FAIL:
            return {...state, loading: false };

        case DELETE:
            return {...state, loading: true };
        case DELETE_SUCCESS:
        case DELETE_FAIL:
            return {...state, loading: false };

        default:
            return state;
    }
}

// Action Creators
export function get(params) {
    return {
        type: LIST,
        payload: {
            request: {
                url: `/clients?${qs.stringify(params)}`,
            },
        },
    };
}

export function getDetails(id) {
    return {
        type: DETAILS,
        payload: {
            request: {
                url: `/clients/${id}`,
            },
        },
    };
}

export function validateRecoveryToken(recovery_token) {
    return {
        type: VALIDATE_RECOVERY_TOKEN,
        payload: {
            request: {
                url: `/auth/check-hash/${recovery_token}`,
            },
        },
    };
}

export function updatePassword(data) {
    return {
        type: UPDATE_PASSWORD,
        payload: {
            request: {
                url: `/auth/new-password`,
                method: "put",
                data: data,
            },
        },
    };
}