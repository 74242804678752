import React from "react";
import _Form from "./../_Form";
import { Card, CardBody, Container, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import {
  add,
  edit,
  get,
  getDetails,
  getGastronomyItems
} from "./../../store/ducks/gastronomyTypes";

import {
  FileUpload,
  Form,
  FormInput,
  FormSelect,
  Header
} from "./../../components";

import Yup from "../../utils/yup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required(),
  status: Yup.string()
    .nullable()
    .required()
});

class GastronomyTypesForm extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      allGastronomyItems: [],
      breadcrumbs: [
        {
          title: "Categorias Culinárias",
          to: this.props.module
        }
      ],
      id: false
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    const { loadingBar } = this.props;
    if (loadingBar) {
      loadingBar.staticStart(35);
    }

    await this.props.getGastronomyItems();
    this.setState({
      allGastronomyItems: this.props.gastronomyItems.map(category => {
        return {
          value: category.id,
          label: category.title
        };
      })
    });

    if (loadingBar) {
      loadingBar.complete();
    }
  }

  render() {
    const { allGastronomyItems, breadcrumbs, fields } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={fields}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validationSchema}
                    render={formProps => (
                      <>
                        <Row>
                          <FormInput
                            label="Título"
                            name="title"
                            lg={4}
                            sm={4}
                            xs={12}
                          />
                          <FormSelect
                            label="Status"
                            name="status"
                            options={[
                              { label: "Ativo", value: 1 },
                              { label: "Inativo", value: 0 }
                            ]}
                            lg={4}
                            sm={4}
                            xs={12}
                          />
                          <FileUpload
                            accept="image/*"
                            initialFile={fields && fields.thumbnail_info}
                            folder="gastronomy-types"
                            label="Imagem"
                            maxSize={3000000}
                            minSize={100}
                            multiple={false}
                            name="file_id"
                            lg={4}
                            sm={4}
                            xs={12}
                          />
                          <FormSelect
                            findDefaultValues={values => {
                              if (values && values.length) {
                                return values.map(val => {
                                  const option = allGastronomyItems.find(i => {
                                    return i.value === val;
                                  });
                                  return option ? { ...option } : null;
                                });
                              } else {
                                return [];
                              }
                            }}
                            isMulti={true}
                            label="Culinárias"
                            name="items"
                            options={allGastronomyItems}
                            lg={12}
                            sm={12}
                            xs={12}
                          />
                        </Row>
                      </>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, gastronomyTypes }) => ({
  details: gastronomyTypes.details,
  gastronomyItems: gastronomyTypes.gastronomyItems,
  loadingBar: common.loadingBar,
  module: gastronomyTypes.module
});

const mapDispatchToProps = {
  add,
  edit,
  get,
  getDetails,
  getGastronomyItems
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GastronomyTypesForm);
