import React from "react";

import { connect } from "react-redux";

import { forgot, login } from "./../store/ducks/auth";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { toast } from "react-toastify";

import Form from "../components/Form";
import FormInput from "../components/FormInput";

import Yup from "../utils/yup";

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .nullable()
    .required(),
  password: Yup.string()
    .nullable()
    .required()
});

const forgotValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .nullable()
    .required()
});

class AuthLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formForgot: false
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormForgotSubmit = this.onFormForgotSubmit.bind(this);
  }

  toggleForm(e) {
    e.preventDefault();
    this.setState({ formForgot: !this.state.formForgot });
  }

  onFormSubmit = async values => {
    return this.props.login(values);
  };

  async onFormForgotSubmit(values) {
    return this.props
      .forgot(values)
      .then(res => {
        if (!res.error) {
          toast(
            "Você receberá um e-mail contendo as instruções para a recuperação da sua senha.",
            {
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: false,
              pauseOnHover: false,
              position: "top-right",
              type: "success"
            }
          );

          this.setState({ formForgot: false });
        }
      })
      .catch(() => {
        toast("Não foi possível recuperar sua senha.", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: false,
          pauseOnHover: false,
          position: "top-right",
          type: "error"
        });
      });
  }

  render() {
    const { formForgot } = this.state;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-neutral border-0">
            <CardHeader className="bg-transparent">
              <img
                alt="Bontempo"
                src={require("../assets/img/brand/bontempo.png")}
                className="img-center img-logo"
              />
            </CardHeader>
            <CardBody className="px-lg-5">
              <div className="text-center text-muted mb-4">
                <small>
                  {formForgot
                    ? "Digite seu e-mail abaixo para recuperar a sua senha."
                    : "Bem-vindo ao app Bontempo."}
                </small>
              </div>
              {!formForgot ? (
                <Form
                  buttonSubmit="Entrar"
                  validationSchema={loginValidationSchema}
                  onSubmit={this.onFormSubmit}
                >
                  <Row>
                    <FormInput label="E-mail" name="email" sm={12} lg={12} />
                    <FormInput
                      label="Senha"
                      name="password"
                      type="password"
                      sm={12}
                      lg={12}
                    />
                  </Row>
                </Form>
              ) : (
                <Form
                  buttonSubmit="Enviar"
                  validationSchema={forgotValidationSchema}
                  onSubmit={this.onFormForgotSubmit}
                >
                  <Row>
                    <FormInput label="E-mail" name="email" sm={12} lg={12} />
                  </Row>
                </Form>
              )}
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-center" xs="12">
              <a
                className="text-light"
                href="#form"
                onClick={event => this.toggleForm(event)}
              >
                <small>
                  {formForgot ? "Voltar para o login" : "Esqueceu sua senha?"}
                </small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default connect(undefined, {
  forgot,
  login
})(AuthLogin);
