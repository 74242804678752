import React, { useState } from "react";
import { Input } from "reactstrap";
import FormField from "./FormField";
import _ from "lodash";

function FormInputComponent({ field, form, ...props }) {
  // Caso o valor esteja em outro nível do objeto, utiliza o lodash para procurar
  const initialValue = props.property
    ? _.get(form.initialValues, props.property)
    : field.value;

  const [innerValue, setInnerValue] = useState(initialValue);

  if (initialValue !== undefined && innerValue === undefined) {
    setInnerValue(initialValue);
    form.setFieldValue(field.name, initialValue);
  }

  function innerOnChange(value) {
    setInnerValue(value);
    form.setFieldValue(field.name, value);
  }

  return (
    <Input
      type="text"
      autoComplete="off"
      {...props}
      invalid={Boolean(
        _.get(form.touched, field.name) && _.get(form.errors, field.name)
      )}
      id={field.name}
      name={field.name}
      defaultValue={innerValue}
      onChange={event => innerOnChange(event.target.value)}
      onBlur={event => form.setFieldTouched(field.name, true)}
    />
  );
}

export default function FormInput(props) {
  return <FormField {...props} component={FormInputComponent} />;
}
