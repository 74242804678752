/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class AuthFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="pb-3">
          <Container fluid className="bg-default">
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="12">
                <div className="copyright text-center text-muted">
                  © 2020{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://www.bontempo.com.br/"
                    target="_blank"
                  >
                    Bontempo
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default AuthFooter;
