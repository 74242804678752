import { number as NumberSchema } from 'yup'

class NumberSchemaExtended extends NumberSchema {
  constructor () {
    super()

    this.withMutation(() => {
      this.transform(value => value === 0 && ((1 / value) < 0) ? undefined : value)
    })
  }
}

export default function () {
  return new NumberSchemaExtended()
}
