import React from "react";

import { connect } from "react-redux";

import { validateRecoveryToken, updatePassword } from "./../store/ducks/auth";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import Form from "../components/Form";
import FormInput from "../components/FormInput";

import Yup from "../utils/yup";

import { toast } from "react-toastify";

const recoverPasswordInitialValues = {
  password: "",
  repeat_password: ""
};

const recoverPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .nullable()
    .required()
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      "A senha deve conter letras e números."
    ),
  repeat_password: Yup.string()
    .nullable()
    .required()
    .test("password-confirm", "As senhas não coincidem", function(value) {
      return this.parent.password === value;
    })
});

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formForgot: false
    };
  }

  async componentDidMount() {
    const { location } = this.props;

    let recovery_token = location.pathname.split("/").pop();
    this.validateRecoveryToken(recovery_token);

    this.onFormRecoverPasswordSubmit = this.onFormRecoverPasswordSubmit.bind(
      this
    );
  }

  async validateRecoveryToken(recovery_token) {
    return this.props
      .validateRecoveryToken(recovery_token)
      .then(res => {
        if (!res.error) {
          this.setState({ formForgot: true });
        }
      })
      .catch(() => {
        toast("Token inválido", {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: false,
          pauseOnHover: false,
          position: "top-right",
          type: "error"
        });
      });
  }

  async onFormRecoverPasswordSubmit(values) {
    values.hash = window.location.pathname.split("/").pop();

    return this.props
      .updatePassword(values)
      .then(res => {
        if (res.payload.data && res.payload.data.id) {
        this.setState({ text: 'Senha atualizada com sucesso!', formForgot: false });
        }
      })
      .catch(err => {
        toast(err.message, {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: false,
          pauseOnHover: false,
          position: "top-right",
          type: "error"
        });
      });
  }

  render() {
    const { formForgot, text } = this.state;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-neutral border-0">
            <CardHeader className="bg-transparent">
              <img
                alt={"Bontempo"}
                src={require("../assets/img/brand/bontempo.png")}
                className="img-center img-logo"
              />
            </CardHeader>
            <CardBody className="px-lg-5">
              {formForgot ? (
                <>
                  <div className="text-center text-muted mb-4">
                    <small>Preencha sua nova senha nos campos abaixo.</small>
                  </div>
                  <Form
                    buttonSubmit="Enviar"
                    initialValues={recoverPasswordInitialValues}
                    validationSchema={recoverPasswordValidationSchema}
                    onSubmit={this.onFormRecoverPasswordSubmit}
                  >
                    <Row>
                      <FormInput
                        label="Senha"
                        name="password"
                        lg={12}
                        sm={12}
                        type="password"
                      />
                      <FormInput
                        label="Confirme a senha"
                        name="repeat_password"
                        lg={12}
                        sm={12}
                        type="password"
                      />
                    </Row>
                  </Form>
                </>
              ) : (
                <div className="text-center text-muted mb-4">
                  <small>
                    {text ? text : "Token de recuperação de senha inválido ou expirado."}
                  </small>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapDispatchToProps = {
  validateRecoveryToken,
  updatePassword
};

export default connect(undefined, mapDispatchToProps)(RecoverPassword);
