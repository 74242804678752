import React from "react";
import { connect } from "react-redux";
import { Formik, Form as FormikForm } from "formik";
import { Button, Spinner } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import _ from "lodash";

const recaptchaRef = React.createRef();

function Form(props) {
  let recaptchaValues = null,
    setGlobalSubmitting = null;

  async function onReset() {
    recaptchaRef.current.reset();
    if (typeof props.onReset === "function") {
      await props.onReset();
    }
  }

  async function onSubmit(values, { setSubmitting }) {
    setGlobalSubmitting = setSubmitting;

    if (props.recaptcha) {
      recaptchaValues = values;
      recaptchaRef.current.execute();
    } else if (typeof props.onSubmit === "function") {
      await props.onSubmit(values);
      setSubmitting(false);
    }
  }

  async function onRecaptchaChange(recaptcha) {
    recaptchaRef.current.reset();
    await props.onSubmit({ ...recaptchaValues, recaptcha });
    if (setGlobalSubmitting) {
      setGlobalSubmitting(false);
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      onReset={onReset}
      onSubmit={onSubmit}
      render={formikProps => (
        <FormikForm>
          {props.children}
          {props.render && props.render(formikProps)}
          <div className="d-flex justify-content-between">
            {props.extraSubmitButton ? props.extraSubmitButton : null}
            {props.buttonReset ? (
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  formikProps.resetForm();
                  onReset();
                }}
              >
                {typeof props.buttonReset === "string" &&
                props.buttonReset.length
                  ? props.buttonReset
                  : "Limpar"}
              </Button>
            ) : (
              <div></div>
            )}
            {props.recaptcha === true && (
              <ReCAPTCHA
                onChange={recaptchaToken => onRecaptchaChange(recaptchaToken)}
                ref={recaptchaRef}
                sitekey="6Lc0esQUAAAAAFdvse_i4HDmbtlhDKfeKRn-f10u"
                size="invisible"
              />
            )}
            {props.buttonSubmit && (
              <Button
                className="save-button"
                color="primary"
                disabled={props.filesLoading}
                onClick={event => {
                  if (!_.isEmpty(formikProps.errors)) {
                    event.preventDefault();
                    event.stopPropagation();

                    formikProps.validateForm().then(validation => {
                      formikProps.setTouched(validation);
                    });

                    toast("Preencha os campos corretamente.", {
                      autoClose: 4000,
                      closeOnClick: true,
                      draggable: true,
                      hideProgressBar: false,
                      pauseOnHover: false,
                      position: "top-right",
                      type: "error"
                    });
                  }
                }}
                type="submit"
              >
                {formikProps.isSubmitting ? (
                  <Spinner type="grow" color="white" size="sm" />
                ) : typeof props.buttonSubmit === "string" &&
                  props.buttonSubmit.length ? (
                  props.buttonSubmit
                ) : (
                  "Enviar"
                )}
              </Button>
            )}
          </div>
        </FormikForm>
      )}
    />
  );
}

const mapStateToProps = ({ files }) => ({
  filesLoading: files.loading
});

export default connect(mapStateToProps)(Form);
