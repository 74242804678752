import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
// reactstrap components
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
// core components
import { AdminNavbar, AdminFooter, Sidebar } from "../components";
import PageNotFound from "../views/PageNotFound";

import { setCurrentRoute, setLoadingBar } from "./../store/ducks/common";
import routes from "../routes";

import LoadingBar from "react-top-loading-bar";

class Admin extends React.Component {
  getRoutes = (routes) => {
    let routesMap = routes.map((prop, key) => {
      if (
        prop.onlyParentSeller === undefined ||
        !prop.onlyParentSeller ||
        (this.props.user && prop.onlyParentSeller && !this.props.user.seller_id)
      ) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
    routesMap.push(<Route component={PageNotFound} key={routesMap.length} />);
    return [routesMap];
  };

  getRouteName = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        this.props.setCurrentRoute(routes[i]);
        return routes[i].name;
      }
    }
    this.props.setCurrentRoute(routes[0]);
    return "Bontempo";
  };

  render() {
    const { jwt } = this.props;

    const showAdminLayout = (
      <>
        <Sidebar
          {...this.props}
          sellerAuth={true}
          routes={routes}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/brand/bontempo.png"),
            imgAlt: "Bontempo",
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getRouteName(this.props.location.pathname)}
            sellerAuth={true}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid className="bg-default">
            <LoadingBar
              height={3}
              onRef={(ref) => this.props.setLoadingBar(ref)}
            />
            <AdminFooter />
          </Container>
        </div>
        <ToastContainer />
      </>
    );

    if (jwt) {
      return showAdminLayout;
    } else {
      return <Redirect to="/auth/login" />;
    }
  }
}

const mapStateToProps = ({ auth }) => ({
  jwt: auth.jwt,
  user: auth.user,
});

const mapDispatchToProps = {
  setCurrentRoute,
  setLoadingBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
