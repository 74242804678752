import React from "react";
import _Form from "./../_Form";
import { Card, Container, Col, Row, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { editPassword, getDetails } from "./../../store/ducks/users";

import { Form, FormInput, Header } from "./../../components";

import Yup from "../../utils/yup";

let validationSchema = Yup.object().shape({
  password: Yup.string()
    .nullable()
    .required()
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])/,
      "A senha deve conter letras e números."
    ),
  repeat_password: Yup.string()
    .nullable()
    .required()
    .test("password-confirm", "As senhas não coincidem", function(value) {
      return this.parent.password === value;
    })
});

class UsersPasswordForm extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      breadcrumbs: [
        {
          title: "Usuários / Alterar Senha",
          to: this.props.module
        }
      ],
      fields: {},
      id: false
    };
  }

  onFormSubmit = async values => {
    let response;

    response = await this.props.editPassword(values, this.state.id);

    let error = true;

    if (!response.error) {
      error = false;
      this.props.history.push(`/admin/${this.props.module}`);
      toast("Senha alterada!", {
        className: "bg-success",
        progressClassName: "bg-white",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success"
      });
    }

    return error;
  };

  render() {
    const { breadcrumbs, fields } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={fields}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validationSchema}
                    render={formProps => (
                      <>
                        <Row>
                          <FormInput
                            label="Senha"
                            name="password"
                            lg={6}
                            sm={6}
                            type="password"
                          />
                          <FormInput
                            label="Confirme a senha"
                            name="repeat_password"
                            lg={6}
                            sm={6}
                            type="password"
                          />
                        </Row>
                      </>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, users }) => ({
  details: users.details,
  loadingBar: common.loadingBar,
  module: users.module
});

const mapDispatchToProps = {
  editPassword,
  getDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPasswordForm);
