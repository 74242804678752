import React from "react";
import _Form from "./../_Form";
import { Card, CardBody, Container, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import { add, edit, get, getDetails } from "./../../store/ducks/climates";

import { Form, FormInput, FormInputNumber, Header } from "./../../components";

import Yup from "../../utils/yup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required(),
  min_temperature: Yup.string()
    .nullable()
    .required(),
  max_temperature: Yup.string()
    .nullable()
    .required()
});

class ClimatesForm extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      breadcrumbs: [
        {
          title: "Climas",
          to: this.props.module
        }
      ],
      id: false
    };
  }

  render() {
    const { breadcrumbs, fields } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={fields}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validationSchema}
                    render={formProps => (
                      <>
                        <Row>
                          <FormInput
                            label="Título"
                            name="title"
                            lg={4}
                            sm={4}
                            xs={12}
                          />
                          <FormInputNumber
                            label="Temperatura mínima"
                            name="min_temperature"
                            maxLenght={3}
                            lg={4}
                            sm={4}
                            xs={12}
                          />
                          <FormInputNumber
                            label="Temperatura máxima"
                            name="max_temperature"
                            maxLenght={3}
                            lg={4}
                            sm={4}
                            xs={12}
                          />
                        </Row>
                      </>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, climates }) => ({
  details: climates.details,
  loadingBar: common.loadingBar,
  module: climates.module
});

const mapDispatchToProps = {
  add,
  edit,
  get,
  getDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(ClimatesForm);
