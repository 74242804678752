import {
  array,
  boolean,
  date,
  lazy,
  mixed,
  object,
  reach,
  ref,
  setLocale,
} from 'yup'

import locale from './locale'

import number from './schemas/number'
import string from './schemas/string'

setLocale(locale)

export default {
  array,
  boolean,
  date,
  lazy,
  mixed,
  number,
  object,
  reach,
  ref,
  string,
}
