import React from "react";
import _Form from "./../_Form";
import { Card, CardBody, Container, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import {
  add,
  edit,
  get,
  getDetails
} from "./../../store/ducks/clothingCategories";

import {
  list as listPlacements
} from "./../../store/ducks/placements";

import {
  Form,
  FormInput,
  FileUpload,
  FormSelect,
  Header
} from "./../../components";

import Yup from "../../utils/yup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required()
});

class ClothingCategoriesForm extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      allCategories: [],
      placements: [],
      breadcrumbs: [
        {
          title: "Categorias",
          to: this.props.module
        }
      ],
      id: false
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    const { loadingBar } = this.props;
    if (loadingBar) {
      loadingBar.staticStart(35);
    }

    await Promise.all([
      this.props.get({ unlimited: true }),
      this.props.listPlacements(),
    ]);
    this.setState({
      allCategories: this.props.list
        .filter(category => !this.state.id || category.id !== this.state.id)
        .map(category => {
          return {
            value: category.id,
            label: category.title
          };
        }),
      placements: this.props.placements.map(category => ({
        value: category.id,
        label: category.title
      })),
    });

    if (loadingBar) {
      loadingBar.complete();
    }
  }

  render() {
    const { allCategories, breadcrumbs, fields, placements } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={fields}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validationSchema}
                    render={formProps => (
                      <>
                        <Row>
                          <FormInput
                            label="Título"
                            name="title"
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                          <FileUpload
                            accept="image/*"
                            initialFile={fields && fields.thumbnail_info}
                            folder="clothing-category"
                            label="Imagem"
                            maxSize={3000000}
                            minSize={100}
                            multiple={false}
                            name="file_id"
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                          <FormSelect
                            findDefaultValues={values => {
                              if (values && values.length) {
                                return values.map(val => {
                                  const option = placements.find(i => {
                                    return i.value === val;
                                  });
                                  return option ? { ...option } : null;
                                });
                              } else {
                                return [];
                              }
                            }}
                            isMulti={true}
                            label="Localização da peça"
                            name="placements"
                            options={placements}
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                          <FormSelect
                            label="Categoria principal"
                            tooltip="Um look só pode conter uma peça de uma categoria marcada como principal, por localização. Exemplo: Um vestido ocupa as localizações de Corpo e Pernas, por isso não será possivel adicionar uma camiseta no mesmo look, porém podemos adicionar um casaco por cima, desde que sua categoria não seja marcada como principal."
                            name="is_main"
                            options={[
                              { label: "Sim", value: true },
                              { label: "Não", value: false }
                            ]}
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                          <FormSelect
                            findDefaultValues={values => {
                              if (values && values.length) {
                                return values.map(val => {
                                  const option = allCategories.find(i => {
                                    return i.value === val;
                                  });
                                  return option ? { ...option } : null;
                                });
                              } else {
                                return [];
                              }
                            }}
                            isMulti={true}
                            label="Categorias que combinam"
                            name="combinations"
                            options={allCategories}
                            lg={12}
                            sm={12}
                            xs={12}
                          />
                        </Row>
                      </>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, clothingCategories, placements }) => ({
  list: clothingCategories.data,
  details: clothingCategories.details,
  loadingBar: common.loadingBar,
  module: clothingCategories.module,
  placements: placements.data,
});

const mapDispatchToProps = {
  add,
  edit,
  get,
  getDetails,
  listPlacements
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClothingCategoriesForm);
