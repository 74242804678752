import qs from "qs";
import axios from "axios";

// Action Types
export const LIST = "bontempo/cities/LIST";
export const LIST_SUCCESS = "bontempo/cities/LIST_SUCCESS";
export const LIST_FAIL = "bontempo/cities/LIST_FAIL";

// Reducer
const initialState = {
  source: null,
  loading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      if (state.source) {
        state.source.cancel("CANCELLED");
      }
      return { ...state, source: action.payload.request.source, loading: true };
    case LIST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };

    default:
      return state;
  }
}

// Action Creators
export function get(params) {
  const source = axios.CancelToken.source();
  return {
    type: LIST,
    payload: {
      request: {
        url: `/cities?${qs.stringify(params)}`,
        source,
        cancelToken: source.token
      }
    }
  };
}
