import React from "react";
import _Form from "./../_Form";
import {
  Badge,
  Card,
  Container,
  Col,
  Row,
  CardBody,
  CardHeader,
  FormGroup,
  Input
} from "reactstrap";

import { connect } from "react-redux";
import { getDetails } from "./../../store/ducks/clients";

import { Header, ReportNumber } from "./../../components";

class ClientsProfile extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      breadcrumbs: [
        {
          title: "Clientes",
          to: this.props.module
        }
      ]
    };
  }

  render() {
    const { breadcrumbs } = this.state;
    const { details, loading } = this.props;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--5 bg-default" fluid>
          {/* Table */}
          {!loading && details && (
            <Row>
              <Col className="mb-5 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                  <Row className="justify-content-center">
                    {details.avatar !== undefined ? (
                      <div
                        style={{
                          backgroundImage: `url(${details.avatar.url})`,
                          backgroundSize: "cover",
                          boxShadow: "var(--secondary) 5px 5px 0px",
                          height: 200,
                          marginTop: -40,
                          width: 200
                        }}
                        className="rounded-circle"
                      />
                    ) : (
                      <i
                        className="fas fa-user-circle text-muted"
                        style={{ fontSize: 200, marginTop: -40 }}
                      />
                    )}
                  </Row>
                  <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                      <h3>{details.name}</h3>
                      <Row>
                        <Col lg="6">
                          <div className="h5 mt-4">
                            <a href={"mailto:" + details.email} target="_blank" rel="noopener noreferrer">
                              <i className="fa fa-envelope mr-2" />
                              {details.email}
                            </a>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="h5 mt-4">
                            <a href={"tel:" + details.phone}>
                              <i className="fa fa-phone mr-2" />

                              {details.phone}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <Row className="mt-4">
                  <Col xl="12">
                    <ReportNumber
                      xs={12}
                      sm={12}
                      className="p-0 pb-3"
                      color="#8B8BCE"
                      icon="fas fa-user-tie"
                      number={details.__meta__.looks_count}
                      title="Looks criados"
                    />
                    <ReportNumber
                      xs={12}
                      sm={12}
                      className="p-0 pb-3"
                      color="#5CB6D8"
                      icon="fas fa-tshirt"
                      number={details.__meta__.clothing_count}
                      title="Peças de roupas cadastradas"
                    />
                    <ReportNumber
                      xs={12}
                      sm={12}
                      className="p-0 pb-3"
                      color="#C7B498"
                      icon="fas fa-pizza-slice"
                      number={details.__meta__.stock_count}
                      title="Quantidade de ingredientes"
                    />
                  </Col>
                </Row>
              </Col>

              <Col className="order-xl-1" xl="8">
                <Card className="bg-white shadow">
                  <CardHeader className="bg-white border-0">
                    <h3 className="mb-0">
                      <i className="fas fa-user-cog" /> Perfil
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              E-mail
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              value={details.email}
                              type="email"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Telefone
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-phone"
                              value={details.phone}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-birthdate"
                            >
                              Possui móveis Bontempo?
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-birthdate"
                              value={details.has_furniture ? "Sim" : "Não"}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-cpf"
                            >
                              CPF
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-cpf"
                              value={details.cpf}
                              type="text"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col lg="12">
                        <Row className="mb-2 pl-3">
                          <h4 className="mb-0">Notícias</h4>
                        </Row>
                        {details.newsCategories.map(interest => (
                          <div className="mb-2">
                            <Badge
                              color="primary"
                              className="mr-2 ml-0 bg-dark text-white"
                            >
                              {interest.title}
                            </Badge>
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row>
                      <Col lg="12">
                        <Row className="mb-2 pl-3">
                          <h4 className="mb-0">Culinária</h4>
                        </Row>
                        {details.gastronomyTypes.map(interest => (
                          <div className="mb-2">
                            <Badge
                              color="primary"
                              className="mr-2 ml-0 bg-dark text-white"
                            >
                              {interest.title}
                            </Badge>
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row>
                      <Col lg="12">
                        <Row className="mb-2 pl-3">
                          <h4 className="mb-0">Filmes</h4>
                        </Row>
                        {details.genres.map(interest => (
                          <div className="mb-2">
                            <Badge
                              color="primary"
                              className="mr-2 ml-0 bg-dark text-white"
                            >
                              {interest.title}
                            </Badge>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, clients }) => ({
  clients: clients.data,
  currentRoute: common.currentRoute,
  details: clients.details,
  loading: clients.loading,
  loadingBar: common.loadingBar,
  module: clients.module
});

const mapDispatchToProps = {
  getDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsProfile);
