// Action Types
export const SET_CURRENT_ROUTE = "bontempo/common/SET_CURRENT_ROUTE";
export const SET_LOADING_BAR = "bontempo/common/SET_LOADING_BAR";
export const SET_BREADCRUMBS = "bontempo/common/SET_BREADCRUMBS";

export const GET_CNPJ = "bontempo/common/GET_CNPJ";
export const GET_CNPJ_SUCCESS = "bontempo/common/GET_CNPJ_SUCCESS";
export const GET_CNPJ_FAIL = "bontempo/common/GET_CNPJ_FAIL";

export const GET_BANKS = "bontempo/common/GET_BANKS";
export const GET_BANKS_SUCCESS = "bontempo/common/GET_BANKS_SUCCESS";
export const GET_BANKS_FAIL = "bontempo/common/GET_BANKS_FAIL";

export const SEARCH_ZIPCODE = "bontempo/places/SEARCH_ZIPCODE";
export const SEARCH_ZIPCODE_SUCCESS = "bontempo/places/SEARCH_ZIPCODE_SUCCESS";
export const SEARCH_ZIPCODE_FAIL = "bontempo/places/SEARCH_ZIPCODE_FAIL";

// Reducer
const initialState = {
  banks: [],
  breadcrumbs: null,
  currentRoute: null,
  cnpjData: null,
  loadingBar: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ROUTE:
      return { ...state, currentRoute: action.payload.route };

    case SET_LOADING_BAR:
      return { ...state, loadingBar: action.payload.ref };

    case SET_BREADCRUMBS:
      return { ...state, breadcrumbs: action.payload.breadcrumbs };

    case GET_CNPJ:
      return { ...state, loading: true };
    case GET_CNPJ_SUCCESS:
      return {
        ...state,
        cnpjData: action.payload.data,
        loading: false
      };
    case GET_CNPJ_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };

    case GET_BANKS:
      return { ...state, loading: true };
    case GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.payload.data.map(bank => {
          return { label: bank.code + " - " + bank.name, value: bank.id };
        }),
        loading: false
      };
    case GET_BANKS_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };

    case SEARCH_ZIPCODE:
      return { ...state, source: null, loading: true };
    case SEARCH_ZIPCODE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SEARCH_ZIPCODE_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };

    default:
      return state;
  }
}

// Action Creators
export function setCurrentRoute(data) {
  return {
    type: SET_CURRENT_ROUTE,
    payload: {
      route: data
    }
  };
}

export function setLoadingBar(ref) {
  return {
    type: SET_LOADING_BAR,
    payload: {
      ref
    }
  };
}

export function setBreadcrumbs(breadcrumbs) {
  return {
    type: SET_BREADCRUMBS,
    payload: {
      breadcrumbs
    }
  };
}

export function getCNPJ(cnpj) {
  return {
    type: GET_CNPJ,
    payload: {
      request: {
        url: `/cnpj?cnpj=${cnpj}`
      }
    }
  };
}

export function getBanks() {
  return {
    type: GET_BANKS,
    payload: {
      request: {
        url: "/banks"
      }
    }
  };
}

export function searchZipcode(zipcode) {
  return {
    type: SEARCH_ZIPCODE,
    payload: {
      request: {
        url: `/zipcode/${zipcode}`
      }
    }
  };
}
