import React, {useState} from "react";

import { Col, Container, Row } from "reactstrap";

import moment from "moment";
import ReactSelect from "react-select";

import {
  BarChart,
  ClientsLocations,
  Header,
  ReportNumber,
} from "./../components";

import { connect } from "react-redux";
import {
  getClientsLocations,
  getGraphics,
  getGraphicsFilters,
  getReports,
} from "./../store/ducks/reports";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import { toast } from "react-toastify";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Modal from "../components/modal";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientsLocations: null,
      clothing: null,
      date: [moment().subtract(30, "days").toDate(), moment().toDate()],
      filters: { category: null, style: null, climate: null },
      graphics: null,
      reports: null,
      modal: false,
      users: null
    };
  }

  componentDidMount() {
    this.getClientsLocations(false);
    this.props.getReports().then((response) => {
      this.setState({ reports: response.payload.data });
    });
    this.onChangeFilter("category", this.state.category);
    this.props.getGraphicsFilters();
  }

  getClientsLocations = async (hasFurniture) => {
    await this.props.getClientsLocations(hasFurniture).then((response) => {
      this.setState({ clientsLocations: response.payload.data });
    });
  };

  onChangeDate = (date) => {
    if (!date) {
      date = [moment().subtract(30, "days").toDate(), moment().toDate()];
    } 
    this.setState({ date }, () => {
      this.onChangeFilter("category", this.state.category);
    });
  };

  onChangeFilter = (field, value) => {
    value = value ? value.value : null;

    this.setState(
      {
        filters: { ...this.state.filters, [field]: value },
      },
      () => {
        this.props
          .getGraphics({
            start_date: moment(this.state.date[0]).format("YYYY-MM-DD"),
            end_date: moment(this.state.date[1]).format("YYYY-MM-DD"),
            clothing_category: this.state.filters.category,
            clothing_climate: this.state.filters.climate,
            clothing_style: this.state.filters.style,
          })
          .then((response) => {
            this.setState({
              clothing: response.payload.data.clothing,
              graphics: response.payload.data,
            });
          });
      }
    );
  };

  render() {
    const { graphicsFilters } = this.props;

    const { clientsLocations, clothing, graphics, reports, modal, users  } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>

          {/* Cards de estatística */}
          {reports ? (
            <Row>
              <ReportNumber
                color="#FFB7B7"
                icon="far fa-user-circle"
                number={reports.registeredClients}
                title="Clientes cadastrados"
                observations={`${reports.registeredClientsWithFurniture} possuem móveis Bontempo.`}
              />
              <ReportNumber
                color="#259179"
                icon="fas fa-user-check"
                number={reports.activeClients30Days}
                title="Clientes ativos nos últimos 30 dias"
              />
              <ReportNumber
                color="#8B8BCE"
                icon="fas fa-user-tie"
                number={reports.registeredLooks}
                title="Looks criados"
                observations={`Média por cliente: ${reports.averageLooks || 0}`}
              />
              <ReportNumber
                color="#5CB6D8"
                icon="fas fa-tshirt"
                number={reports.registeredClothing}
                title="Peças de roupas cadastradas"
                observations={`Média por cliente: ${
                  reports.averageClothing || 0
                }`}
              />
              <ReportNumber
                color="#C7B498"
                icon="fas fa-pizza-slice"
                number={reports.registeredStock}
                title="Quantidade de ingredientes"
                observations={`Média por cliente: ${reports.averageStock || 0}`}
              />
            </Row>
          ) : null}

          {/* Mapa */}
          {clientsLocations ? (
            <ClientsLocations
              clientsLocations={clientsLocations}
              onChange={this.getClientsLocations}
            />
          ) : null}

          <Modal open={modal} users={users} toggle={() => {
            this.setState({modal: false})
          }} />

          {/* Gráficos */}
          {graphics ? (
            <Row>
              <Col xs={12} sm={6} className="mt-5 mb-4">
                <h1 className="text-white">Gráficos por período</h1>
              </Col>
              <Col xs={12} sm={6} className="mt-5 mb-4 text-right">
                <DateRangePicker
                  className="bg-white"
                  onChange={this.onChangeDate}
                  value={this.state.date}
                />
              </Col>
              <Col xs={12} className="mb-5">
                <h2 className="text-white">Novos cadastros</h2>
                <div
                  className="bg-secondary p-3 rounded d-flex justify-content-start align-items-center"
                  style={{ height: "100%" }}
                >
                  <ResponsiveContainer width="100%" aspect={3}>
                    <LineChart onClick={(event) => {
                      var date = event.activeLabel;
                      var momentObj = moment(date, 'DD-MM-YYYY');
                      var final = momentObj.format('YYYY-MM-DD');
                      let result = graphics.main.filter(e => {
                        return e.dayT === final && e.new_clients > 0;
                      })
                      if(result.length > 0)
                      {
                        console.log(result[0].userLog);
                        this.setState({modal: true, users: result[0].userLog});
                      }
                    }}
                      data={graphics.main.map((item) => {
                        return {
                          name: moment(item.day).format("DD-MM-YYYY"),
                          "Novos clientes": item.new_clients || 0,
                          "Com móveis Bontempo":
                            item.new_clients_with_furniture || 0,
                          "Sem móveis Bontempo":
                            item.new_clients_without_furniture || 0,
                        };
                      })}
                    >
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Novos clientes"
                        stroke="#5CB6D8"
                        strokeWidth={3}
                      />
                      <Line
                        type="monotone"
                        dataKey="Com móveis Bontempo"
                        stroke="#8884d8"
                        strokeWidth={3}
                      />
                      <Line
                        type="monotone"
                        dataKey="Sem móveis Bontempo"
                        stroke="#82ca9d"
                        strokeWidth={3}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Col>
              <Col xs={12} className="my-5">
                <Row className="pb-3">
                  <Col xs={12} sm={3}>
                    <h2 className="text-white">Peças de roupas cadastradas</h2>
                  </Col>
                  <Col xs={12} sm={3}>
                    {graphicsFilters && (
                      <ReactSelect
                        classNamePrefix="select-form"
                        className={`form-select`}
                        isClearable={true}
                        onChange={(value) =>
                          this.onChangeFilter("category", value)
                        }
                        options={graphicsFilters.categories.map((item) => {
                          return {
                            label: item,
                            value: item,
                          };
                        })}
                        noOptionsMessage={() => "Nenhuma opção encontrada."}
                        placeholder={"Filtre por categoria..."}
                      />
                    )}
                  </Col>
                  <Col xs={12} sm={3}>
                    {graphicsFilters && (
                      <ReactSelect
                        classNamePrefix="select-form"
                        className={`form-select`}
                        isClearable={true}
                        onChange={(value) =>
                          this.onChangeFilter("style", value)
                        }
                        options={graphicsFilters.styles.map((item) => {
                          return {
                            label: item,
                            value: item,
                          };
                        })}
                        noOptionsMessage={() => "Nenhuma opção encontrada."}
                        placeholder={"Filtre por estilo..."}
                      />
                    )}
                  </Col>
                  <Col xs={12} sm={3}>
                    {graphicsFilters && (
                      <ReactSelect
                        classNamePrefix="select-form"
                        className={`form-select`}
                        isClearable={true}
                        onChange={(value) =>
                          this.onChangeFilter("climate", value)
                        }
                        options={graphicsFilters.climates.map((item) => {
                          return {
                            label: item,
                            value: item,
                          };
                        })}
                        noOptionsMessage={() => "Nenhuma opção encontrada."}
                        placeholder={"Filtre por clima..."}
                      />
                    )}
                  </Col>
                </Row>

                {clothing && (
                  <div
                    className="bg-secondary p-3 rounded d-flex justify-content-start align-items-center"
                    style={{ height: "100%" }}
                  >
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        data={clothing.map((item) => {
                          return {
                            name: moment(item.day).format("DD/MM"),
                            Total: item.total || 0,
                          };
                        })}
                      >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="Total"
                          stroke="#5CB6D8"
                          strokeWidth={3}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </Col>
              
              <>
                <BarChart
                  color="#D85B5B"
                  data={graphics.newsCategories}
                  title="Notícias"
                  totalNewClients={graphics.totalNewClients}
                />
                <BarChart
                  color="#42D6CB"
                  data={graphics.genres}
                  title="Filmes"
                  totalNewClients={graphics.totalNewClients}
                />
                <BarChart
                  color="#FFDE00"
                  data={graphics.gastronomyTypes}
                  title="Culinária"
                  totalNewClients={graphics.totalNewClients}
                />
                <BarChart
                  color="#FFB7B7"
                  data={graphics.clothingColor}
                  title="Cores de roupas"
                  totalNewClients={graphics.totalNewClients}
                />
              </>
              <Col xs={12} className="my-5">
                <h2 className="text-white">Looks agendados</h2>
                <div
                  className="bg-secondary p-3 rounded d-flex justify-content-start align-items-center"
                  style={{ height: "100%" }}
                >
                  <ResponsiveContainer width="100%" aspect={3}>
                    <LineChart
                      data={graphics.lookSchedule.map((item) => {
                        return {
                          name: moment(item.day).format("DD/MM"),
                          Total: item.total || 0,
                        };
                      })}
                    >
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Total"
                        stroke="#5CB6D8"
                        strokeWidth={3}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ reports }) => (
  {
  clientsLocations: reports.clientsLocations,
  graphics: reports.graphics,
  graphicsFilters: reports.graphicsFilters,
  loadingReports: reports.loadingReports,
  loadingClientsLocations: reports.loadingClientsLocations,
  loadingGraphics: reports.loadingGraphics,
  reports: reports.reports,
});

const mapDispatchToProps = {
  getClientsLocations,
  getGraphics,
  getGraphicsFilters,
  getReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
