import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Spinner } from "reactstrap";
import FormField from "./FormField";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { uploadSingle, UPLOAD_SINGLE_SUCCESS } from "./../store/ducks/files";
import _ from "lodash";

function FileUploadComponent({ field, form, initialFile, ...props }) {
  const [file, setFile] = useState();
  const [loading, setIsLoading] = useState(false);
  const fieldName = props.property || field.name;
  return (
    <Dropzone
      multiple={false}
      {...props}
      onDropAccepted={async acceptedFiles => {
        if (!props.folder) {
          return;
        }

        setFile(acceptedFiles[0]);
        setIsLoading(true);

        const upload = await props.uploadSingle(acceptedFiles[0], props.folder);

        if (upload.type === UPLOAD_SINGLE_SUCCESS) {
          form.setFieldValue(field.name, upload.payload.data.id);
        }

        toast(
          upload.type === UPLOAD_SINGLE_SUCCESS
            ? "O arquivo foi salvo."
            : "Não foi possível subir o arquivo.",
          {
            className: null,
            progressClassName: "bg-white",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            type: upload.type === UPLOAD_SINGLE_SUCCESS ? "success" : "error"
          }
        );
        setIsLoading(false);
      }}
      onDropRejected={files => {
        files.map(file =>
          toast(`O arquivo ${file.name} é inválido.`, {
            className: null,
            progressClassName: "bg-white",
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            type: "error"
          })
        );
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div className="dropzone-wrapper">
          <span className="label">{props.label}</span>
          <div
            {...getRootProps({
              className: `dropzone ${
                _.get(form.touched, fieldName) && _.get(form.errors, fieldName)
                  ? "error"
                  : null
              }`
            })}
          >
            <input {...getInputProps()} />
            <input
              type="hidden"
              defaultValue={
                // Caso o valor esteja em outro nível do objeto, utiliza o lodash para procurar
                props.property
                  ? _.get(form.initialValues, props.property)
                  : field.value
              }
              invalid={Boolean(
                _.get(form.touched, fieldName) && _.get(form.errors, fieldName)
              )}
              name={props.name}
              onChange={event =>
                form.setFieldValue(field.name, event.target.value)
              }
              onBlur={event => form.setFieldTouched(field.name, true)}
            />
            <div className="file-name-wrapper">
              <span className="file-name">
                {file && file.path ? file.path : initialFile && initialFile.key}
              </span>
              {loading ? (
                <Spinner
                  type="grow"
                  color="primary"
                  size="sm"
                  className="mr-2"
                />
              ) : (
                <i className="fas fa-paperclip" />
              )}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}

function FileUpload(props) {
  return <FormField {...props} component={FileUploadComponent} />;
}

const mapDispatchToProps = {
  uploadSingle
};

export default connect(null, mapDispatchToProps)(FileUpload);
