import React, { useState } from "react";

import {
  ErrorMessage as FormikErrorMessage,
  Field as FormikField
} from "formik";

import { Col, FormFeedback, FormGroup, Label, Tooltip } from "reactstrap";

export default function FormField({
  component,
  label,
  lg,
  name,
  sm,
  xs,
  fieldId,
  tooltip,
  ...props
}) {
  if (props.inputOnly) {
    delete props.inputOnly;
    return <FormikField {...props} component={component} name={name} />;
  }

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  delete props.inputOnly;
  return (
    <Col xs={xs || 12} sm={sm || "6"} lg={lg || "4"}>
      <FormGroup>
        {label && (
          <Label for={(fieldId ? fieldId : name)}>
            {label}
            {tooltip &&
            <React.Fragment>
              <small className="ml-2 rounded-circle bg-primary px-1 text-white tooltip-icon" id={`tooltip-${(fieldId ? fieldId : name).replace(/\./g,'')}`}>
                <i className="fa fa-question"></i>
              </small>
              <Tooltip placement="top" isOpen={tooltipOpen} target={`tooltip-${(fieldId ? fieldId : name).replace(/\./g,'')}`} toggle={toggleTooltip} innerClassName="text-xs">
                {tooltip}
              </Tooltip>
            </React.Fragment>
            }
          </Label>
        )}
        <FormikField {...props} component={component} name={name} />
        <FormikErrorMessage
          name={name}
          render={msg => (
            <FormFeedback style={{ display: "block" }}>{msg}</FormFeedback>
          )}
        />
      </FormGroup>
    </Col>
  );
}
