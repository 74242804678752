import React from "react";
import { Badge, Container } from "reactstrap";
import _List from "./../_List";

import { connect } from "react-redux";
import { get, remove } from "./../../store/ducks/clothingCategories";

import { ContentTable, Header } from "./../../components";

class ClothingCategoriesList extends _List {
  render() {
    const { data } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--7 bg-default" fluid>
          <ContentTable
            columns={[
              {
                title: "Título",
                slug: "title"
              },
              {
                title: "Categoria principal",
                slug: "isMain",
                align: "center"
              },
              {
                title: "Localização",
                slug: "placements",
                align: "center"
              }
            ]}
            data={data}
            hasInsert={true}
            middleware={row => {
              row.isMain = (
                <strong
                  className={row.is_main ? "text-success" : "text-danger"}
                >
                  {row.is_main ? "Sim" : "Não"}
                </strong>
              );
              row.placements = row.placements.map(item => (
                <Badge color="success" className="ml-1 mt-1">{item.title}</Badge>
              ));

              return row;
            }}
            onDelete={this.props.remove}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, clothingCategories }) => ({
  currentRoute: common.currentRoute,
  data: clothingCategories.data,
  loading: clothingCategories.loading,
  loadingBar: common.loadingBar
});

const mapDispatchToProps = {
  get,
  remove
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClothingCategoriesList);
