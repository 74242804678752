import React, { useState } from "react";
import memoize from "memoize-one";
import { ChromePicker } from "react-color";

import FormMasked from "./FormMasked";

const mask = memoize(rawValue => {
  return ["#", /\w/, /\w/, /\w/, /\w/, /\w/, /\w/].filter(Boolean);
});

function FormInputColor(props) {
  const [display, setDisplay] = useState(false);
  const [color, setColor] = useState("");

  const handleClick = () => {
    setDisplay(!display);
  };

  const handleClose = () => {
    setDisplay(false);
  };

  const handleChangeComplete = color => {
    setColor(color.hex);
  };

  const onChange = color => {
    setColor(color);

    if (props.onChange) {
      props.onChange(color);
    }
  };

  return (
    <>
      <FormMasked
        {...props}
        mask={mask}
        onChange={onChange}
        onFocus={() => {
          handleClick();
        }}
        value={color}
      />
      {display ? (
        <div className="color-input">
          <div
            style={{
              position: "absolute",
              zIndex: "2"
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px"
              }}
              onClick={handleClose}
            />
            <div className="color-input-colorpicker">
              <ChromePicker
                color={color}
                onChangeComplete={handleChangeComplete}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default FormInputColor;
