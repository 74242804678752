import React, { useState } from "react";
import FormField from "./FormField";
import _ from "lodash";
import RichTextEditor from "react-rte";

function FormRichTextarea({ field, form, ...props }) {
  // Caso o valor esteja em outro nível do objeto, utiliza o lodash para procurar
  const initialValue = props.property
    ? _.get(form.initialValues, props.property)
    : field.value;

  const [innerValue, setInnerValue] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [isInitialized, setIsInitialized] = useState(false);

  if (initialValue) {
    let cleanInitialValue = document.createElement("DIV");
    cleanInitialValue.innerHTML = initialValue.toString("markdown").trim();
    cleanInitialValue =
      cleanInitialValue.textContent || cleanInitialValue.innerText || "";

    let cleanInnerValue = document.createElement("DIV");
    cleanInnerValue.innerHTML = innerValue.toString("markdown").trim();
    cleanInnerValue =
      cleanInnerValue.textContent || cleanInnerValue.innerText || "";

    if (
      !isInitialized &&
      cleanInitialValue.trim().length !== cleanInnerValue.trim().length
    ) {
      setIsInitialized(true);
      setInnerValue(RichTextEditor.createValueFromString(initialValue, "html"));
      form.setFieldValue(field.name, initialValue);
    }
  }

  function innerOnChange(value) {
    setInnerValue(value);
    form.setFieldValue(field.name, value.toString("html"));
  }

  return (
    <RichTextEditor
      {...props}
      id={field.name}
      name={field.name}
      onChange={value => innerOnChange(value)}
      value={innerValue}
      toolbarConfig={{
        // Optionally specify the groups to display (displayed in the order listed).
        display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
        INLINE_STYLE_BUTTONS: [
          { label: "Bold", style: "BOLD", className: "custom-css-class" },
          { label: "Italic", style: "ITALIC" },
          { label: "Underline", style: "UNDERLINE" }
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: "UL", style: "unordered-list-item" },
          { label: "OL", style: "ordered-list-item" }
        ]
      }}
    />
  );
}

export default function FormInput(props) {
  return <FormField {...props} component={FormRichTextarea} />;
}
