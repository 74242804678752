import { addMethod, string as StringSchema } from 'yup'

import date from './date'
import datetime from './datetime'
import phone from './phone'

addMethod(StringSchema, 'date', date)
addMethod(StringSchema, 'datetime', datetime)
addMethod(StringSchema, 'phone', phone)

export default StringSchema
