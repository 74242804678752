import React from "react";
import { Container } from "reactstrap";
import _List from "./../_List";

import { connect } from "react-redux";
import { get, remove } from "./../../store/ducks/recipes";

import { ContentTable, Header } from "./../../components";

class RecipesList extends _List {
  render() {
    const { data } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--7 bg-default" fluid>
          <ContentTable
            columns={[
              {
                title: "Título",
                slug: "title"
              },
              {
                title: "Status",
                slug: "status"
              }
            ]}
            data={data}
            hasInsert={true}
            middleware={row => {
              row.status = (
                <strong className={row.status ? "text-success" : "text-danger"}>
                  {row.status ? "Ativo" : "Inativo"}
                </strong>
              );

              return row;
            }}
            onDelete={this.props.remove}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, recipes }) => ({
  currentRoute: common.currentRoute,
  data: recipes.data,
  loading: recipes.loading,
  loadingBar: common.loadingBar
});

const mapDispatchToProps = {
  get,
  remove
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipesList);
