import React from "react";
import _Form from "./../_Form";
import { Card, CardBody, Container, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import { add } from "./../../store/ducks/notifications";
import { toast } from "react-toastify";

import { Form, FormInput, Header } from "./../../components";

import Yup from "../../utils/yup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required(),
  text: Yup.string()
    .nullable()
    .required()
});

class NotificationsForm extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      breadcrumbs: [
        {
          title: "Notificações",
          to: this.props.module
        }
      ],
      id: false
    };
  }

  onFormSubmit = async values => {
    let response;

    response = await this.props.add(values);

    let message = "Notificações enviadas!",
      error = true;

    if (!response.error) {
      error = false;
      this.props.history.push(`/admin/dashboard`);
      toast(message, {
        className: "bg-success",
        progressClassName: "bg-white",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success"
      });
    }

    return error;
  };

  render() {
    const { breadcrumbs, fields } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={fields}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validationSchema}
                    render={formProps => (
                      <>
                        <Row>
                          <FormInput
                            label="Título"
                            name="title"
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                          <FormInput
                            label="Texto"
                            name="text"
                            lg={6}
                            sm={6}
                            xs={12}
                          />
                        </Row>
                      </>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, notifications }) => ({
  loadingBar: common.loadingBar,
  module: notifications.module
});

const mapDispatchToProps = {
  add
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsForm);
