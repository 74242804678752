// Action Types

export const SAVE = "bontempo/climates/SAVE";
export const SAVE_SUCCESS = "bontempo/climates/SAVE_SUCCESS";
export const SAVE_FAIL = "bontempo/climates/SAVE_FAIL";

// Reducer
const initialState = {
  loading: false,
  module: "notificacoes"
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, loading: true };
    case SAVE_SUCCESS:
    case SAVE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

// Action Creators

export function add(data) {
  return {
    type: SAVE,
    payload: {
      request: {
        url: `/notifications`,
        method: "post",
        data: data
      }
    }
  };
}
