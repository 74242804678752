// Action Types
export const LIST = "bontempo/climates/LIST";
export const LIST_SUCCESS = "bontempo/climates/LIST_SUCCESS";
export const LIST_FAIL = "bontempo/climates/LIST_FAIL";

// Reducer
const initialState = {
  data: [],
  loading: false,
  total: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return { ...state, loading: true };
    case LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        total: action.payload.data.total,
        loading: false
      };
    case LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };
    default:
      return state;
  }
}

// Action Creators
export function list(params) {
  return {
    type: LIST,
    payload: {
      request: {
        url: `/placements`
      }
    }
  };
}