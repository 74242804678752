import React from 'react'

import FormMasked from './FormMasked'

const mask = [
  /\d/, /\d/, '/',
  /\d/, /\d/, '/',
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, ':',
  /\d/, /\d/,
]

function FormInputDatetime (props) {
  return (
    <FormMasked
      {...props}
      mask={mask}
    />
  )
}

export default FormInputDatetime
