import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactScrollableList from './ScrollableList'

const Modall = ({open, users, toggle}) => {
  console.log('modal users - ', users);
    if(!open) return null;
    return(
       <>
       <Modal isOpen={open} 
       toggle={toggle} 
       backdrop={true} 
       centered={true} 
       fade={true}
       scrollable={true}>
        <ModalHeader
        style={{backgroundColor: '#1d262b', display: 'flex', justifyContent: 'space-around',
       borderTop: '2px solid', borderLeft: '2px solid', borderRight: '2px solid', borderColor: '#23e66e'}}  close={true} toggle={toggle}>
          <p style={{color: 'white', fontSize: '2vh', fontWeight: '800'}}>
            Lista de usuários
          </p>
        </ModalHeader>
        <ModalBody style={{backgroundColor: '#1d262b', borderBottom: '2px solid', borderLeft: '2px solid', borderRight: '2px solid', borderColor: '#23e66e'}} >
        <ReactScrollableList
          listItems={users}
          heightOfItem={30}
          maxItemsToRender={50}
          style={{ color: '#333' }}/>
        </ModalBody>
      </Modal>
       </>
    )
}
export default Modall;