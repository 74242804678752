import { combineReducers } from "redux";

import { createStore, applyMiddleware } from "redux";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createFilter from "redux-persist-transform-filter";
import { toast } from "react-toastify";

import { logout } from "./ducks/auth";

import auth from "./ducks/auth";
import cities from "./ducks/cities";
import clients from "./ducks/clients";
import climates from "./ducks/climates";
import clothingCategories from "./ducks/clothingCategories";
import clothingColors from "./ducks/clothingColors";
import clothingStyles from "./ducks/clothingStyles";
import common from "./ducks/common";
import files from "./ducks/files";
import gastronomyTypes from "./ducks/gastronomyTypes";
import genres from "./ducks/genres";
import newsCategories from "./ducks/newsCategories";
import notifications from "./ducks/notifications";
import placements from "./ducks/placements";
import recipes from "./ducks/recipes";
import reports from "./ducks/reports";
import users from "./ducks/users";

export const reducer = combineReducers({
    auth,
    cities,
    clients,
    climates,
    clothingCategories,
    clothingColors,
    clothingStyles,
    common,
    files,
    gastronomyTypes,
    genres,
    newsCategories,
    notifications,
    placements,
    recipes,
    reports,
    users
});

const client = axios.create({
    baseURL: process.env.NODE_ENV === "production" ?
        "https://api.bontempo.com.br/" : "http://localhost:3333/",

    headers: {
        "api-token": "pYtxBhQerhDfYuIBVgijasSDLpQmnCug"
    },
    responseEncoding: "utf8",
    responseType: "json",
    accept: "application/json"
});

const middlewareAxios = axiosMiddleware(client, {
    interceptors: {
        request: [
            (getState, config) => {
                const currentState = store.getState();
                config.headers["Authorization"] = `Bearer ${currentState.auth.jwt}`;
                return config;
            }
        ],
        response: [{
            error: ({ dispatch }, error) => {
                if (error.response && error.response.status === 401) {
                    store.dispatch(logout());
                    let message = "Você precisa realizar login novamente.";
                    if (error.response && error.response.data.message) {
                        message = error.response.data.message;
                    } else if (error.response && error.response.data.error.message) {
                        message = error.response.data.error.message;
                    }
                    toast(message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        type: "error"
                    });
                } else {
                    let message = "Ocorreu um erro na requisição!";
                    if (error.response && error.response.data.length) {
                        message = error.response.data[0].message;
                    } else if (error.response.data.message) {
                        message = error.response.data.message;
                    } else if (error.response.data.error.message) {
                        message = error.response.data.error.message;
                    }
                    // Mostra Alert padrão de erro de requisição
                    toast(message, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        type: "error"
                    });
                }
                return Promise.reject(error);
            }
        }]
    }
});

const persistConfig = {
    key: "root",
    storage,
    timeout: null,
    transforms: [createFilter("auth", ["user", "jwt"])],
    whitelist: ["auth"]
};
const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = applyMiddleware(middlewareAxios, logger);

export const store = createStore(persistedReducer, middleware);
export const persistor = persistStore(store);