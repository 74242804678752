import React, { Component } from "react";
import FormSelect from "./FormSelect";

import { connect } from "react-redux";
import { get } from "./../store/ducks/cities";

class FormSelectCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
  }

  render() {
    return (
      <FormSelect
        {...this.props}
        options={
          this.props.defaultValue && !this.state.options.length
            ? this.props.defaultValue.length !== undefined
              ? this.props.defaultValue
              : [this.props.defaultValue]
            : this.state.options
        }
        onInputChange={string => {
          if (string.length) {
            this.props.get({ search: string }).then(response => {
              if (response.payload && response.payload.data) {
                this.setState({
                  options: response.payload.data.data.map(city => {
                    return {
                      value: city.id,
                      label: `${city.name} - ${city.state.uf}`
                    };
                  })
                });
              }
            });
          } else {
            this.setState({ options: [] });
          }
        }}
      />
    );
  }
}

const mapDispatchToProps = { get };

export default connect(null, mapDispatchToProps)(FormSelectCity);
