import qs from "qs";

// Action Types
export const LIST = "bontempo/climates/LIST";
export const LIST_SUCCESS = "bontempo/climates/LIST_SUCCESS";
export const LIST_FAIL = "bontempo/climates/LIST_FAIL";

export const DETAILS = "bontempo/climates/DETAILS";
export const DETAILS_SUCCESS = "bontempo/climates/DETAILS_SUCCESS";
export const DETAILS_FAIL = "bontempo/climates/DETAILS_FAIL";

export const SAVE = "bontempo/climates/SAVE";
export const SAVE_SUCCESS = "bontempo/climates/SAVE_SUCCESS";
export const SAVE_FAIL = "bontempo/climates/SAVE_FAIL";

export const DELETE = "bontempo/climates/DELETE";
export const DELETE_SUCCESS = "bontempo/climates/DELETE_SUCCESS";
export const DELETE_FAIL = "bontempo/climates/DELETE_FAIL";

// Reducer
const initialState = {
  data: null,
  details: null,
  loading: false,
  module: "looks/climas",
  total: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST:
      return { ...state, loading: true };
    case LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    case LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };

    case DETAILS:
      return { ...state, loading: true };
    case DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        loading: false
      };
    case DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: "Não foi possível completar a requisição."
      };

    case SAVE:
      return { ...state, loading: true };
    case SAVE_SUCCESS:
    case SAVE_FAIL:
      return { ...state, loading: false };

    case DELETE:
      return { ...state, loading: true };
    case DELETE_SUCCESS:
    case DELETE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

// Action Creators
export function get(params) {
  return {
    type: LIST,
    payload: {
      request: {
        url: `/climates?${qs.stringify(params)}`
      }
    }
  };
}

export function getDetails(id) {
  return {
    type: DETAILS,
    payload: {
      request: {
        url: `/climates/${id}`
      }
    }
  };
}

export function add(data) {
  return {
    type: SAVE,
    payload: {
      request: {
        url: `/climates`,
        method: "post",
        data: data
      }
    }
  };
}

export function edit(data, id) {
  return {
    type: SAVE,
    payload: {
      request: {
        url: `/climates/${id}`,
        method: "put",
        data: data
      }
    }
  };
}

export function remove(id) {
  return {
    type: DELETE,
    payload: {
      request: {
        url: `/climates/${id}`,
        method: "delete"
      }
    }
  };
}
