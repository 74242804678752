import React from "react";
import _Form from "./../_Form";
import {
  Button,
  Card,
  CardBody,
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

import { connect } from "react-redux";
import { add, edit, get, getDetails } from "./../../store/ducks/recipes";
import { get as getGastronomyTypes } from "./../../store/ducks/gastronomyTypes";

import {
  FileUpload,
  Form,
  FormInput,
  FormRichTextarea,
  FormSelect,
  Header
} from "./../../components";

import Yup from "../../utils/yup";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required(),
  type_id: Yup.string()
    .nullable()
    .required()
});

class RecipesForm extends _Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      activeTab: "1",
      breadcrumbs: [
        {
          title: "Receitas",
          to: this.props.module
        }
      ],
      id: false,
      ingredients: []
    };
  }

  async componentDidMount() {
    this.props.getGastronomyTypes({ unlimited: 1 });
    await super.componentDidMount();

    this.setState({
      ingredients: this.state.id ? this.props.details.ingredients : []
    });
  }

  addIngredient = () => {
    this.setState({ ingredients: [...this.state.ingredients, { title: "" }] });
  };

  deleteIngredient = index => {
    let ingredients = [...this.state.ingredients];
    delete ingredients[index];

    this.setState({ ingredients: ingredients.filter(Boolean) });
  };

  render() {
    const { breadcrumbs, fields } = this.state;

    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
        {/* Page content */}
        <Container className="mt--7 bg-default" fluid>
          {/* Table */}
          <Row>
            <Col>
              <Card className="shadow">
                <CardBody className="position-relative">
                  <Form
                    buttonSubmit="Salvar"
                    initialValues={{
                      ...fields,
                      ingredients: this.state.ingredients
                    }}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validationSchema}
                    render={formProps => (
                      <>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={{
                                active: this.state.activeTab === "1"
                              }}
                              onClick={() => {
                                this.toggleTab("1");
                              }}
                            >
                              Informações
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={{
                                active: this.state.activeTab === "2"
                              }}
                              onClick={() => {
                                this.toggleTab("2");
                              }}
                            >
                              Ingredientes
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <Row>
                              <FormInput
                                label="Título"
                                name="title"
                                lg={8}
                                sm={8}
                                xs={12}
                              />
                              <FormSelect
                                label="Status"
                                name="status"
                                options={[
                                  { label: "Ativo", value: 1 },
                                  { label: "Inativo", value: 0 }
                                ]}
                                lg={4}
                                sm={4}
                                xs={12}
                              />
                              <FormSelect
                                label="Categoria"
                                name="type_id"
                                options={
                                  this.props.categories &&
                                  !this.props.categories.data
                                    ? this.props.categories.map(category => {
                                        return {
                                          label: category.title,
                                          value: category.id
                                        };
                                      })
                                    : []
                                }
                                lg={4}
                                sm={4}
                                xs={12}
                              />
                              <FormInput
                                label="Vídeo (link do Youtube)"
                                name="video"
                                lg={4}
                                sm={4}
                                xs={12}
                              />
                              <FileUpload
                                accept="image/*"
                                initialFile={fields && fields.thumbnail_info}
                                folder="recipes"
                                label="Imagem"
                                maxSize={3000000}
                                minSize={100}
                                multiple={false}
                                name="file_id"
                                lg={4}
                                sm={4}
                                xs={12}
                              />
                            </Row>
                            <Row>
                              <FormRichTextarea
                                label="Modo de preparo"
                                name="preparation"
                                lg={6}
                                sm={12}
                                xs={12}
                              />
                              <FormRichTextarea
                                label="Informações"
                                name="information"
                                lg={6}
                                sm={12}
                                xs={12}
                              />
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Button
                              className="mb-2"
                              color="info"
                              onClick={this.addIngredient}
                              size={"sm"}
                              type="button"
                            >
                              <i class="fas fa-plus" /> Adicionar ingrediente
                            </Button>
                            <Row>
                              {this.state.ingredients &&
                              this.state.ingredients.length
                                ? this.state.ingredients.map(
                                    (ingredient, i) => (
                                      <Col xs={12} sm={6}>
                                        <Row>
                                          <FormInput
                                            label="Ingrediente"
                                            name={`ingredients[${i}][title]`}
                                            lg={10}
                                            sm={10}
                                            xs={12}
                                          />
                                          <Col lg={2} sm={2} xs={2}>
                                            <Button
                                              className="mt-4"
                                              color="danger"
                                              onClick={() =>
                                                this.deleteIngredient(i)
                                              }
                                              size={"sm"}
                                              type="button"
                                            >
                                              <i class="fas fa-trash" />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Col>
                                    )
                                  )
                                : null}
                            </Row>
                          </TabPane>
                        </TabContent>
                      </>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, gastronomyTypes, recipes }) => ({
  categories: gastronomyTypes.data,
  details: recipes.details,
  loadingBar: common.loadingBar,
  module: recipes.module
});

const mapDispatchToProps = {
  add,
  edit,
  get,
  getDetails,
  getGastronomyTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipesForm);
