import printValue from "./utils/print-value";

/* eslint-disable no-template-curly-in-string */

export default {
  mixed: {
    default: "Este campo está inválido",
    required: "Este campo é obrigatório",
    oneOf: "Este campo deve conter um destes valores: ${values}",
    notOneOf: "Este campo não deve conter nenhum destes valores: ${values}",
    notType: ({ path, type, value, originalValue }) => {
      const isCast = originalValue != null && originalValue !== value;

      let msg =
        `Este campo deve ser do tipo \`${type}\`, ` +
        `mas o valor final é: \`${printValue(value, true)}\`` +
        (isCast ? ` (vindo de \`${printValue(originalValue, true)}\`).` : ".");

      if (value === null) {
        msg += `\n Se "null" for um valor vazio intencional, marque o esquema schema como \`.nullable().required()\``;
      }

      return msg;
    }
  },
  string: {
    length: "Este campo deve ter exatamente ${length} caracteres",
    min: "Este campo deve ter no mínimo ${min} caracteres",
    max: "Este campo deve ter no máximo ${max} caracteres",
    matches: 'Este campo deve estar no formato "${regex}"',
    email: "Este campo deve ser um e-mail válido",
    url: "Este campo deve ser uma URL válida",
    trim: "Este campo deve ser uma string aparada",
    lowercase: "Este campo deve estar todo em letras minúsculas",
    uppercase: "Este campo deve estar todo em letras maiúsculas"
  },
  number: {
    min: "Este campo deve ser igual ao maior a ${min}",
    max: "Este campo deve ser igual ao menor a ${max}",
    lessThan: "Este campo deve ser menor que ${less}",
    moreThan: "Este campo deve ser maior que ${more}",
    notEqual: "Este campo não deve ser igual a ${notEqual}",
    positive: "Este campo deve ser um número positivo",
    negative: "Este campo deve ser um número negativo",
    integer: "Este campo deve ser um número inteiro"
  },
  date: {
    min: "Este campo deve ser depois de ${min}",
    max: "Este campo deve ser antes de ${max}"
  },
  boolean: {},
  object: {
    noUnknown:
      "Este campo não deve conter campos não específicados no formato do objeto"
  },
  array: {
    min: "Este campo deve conter no mínimo ${min} itens",
    max: "Este campo deve conter no máximo ${min} itens"
  }
};
