import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
// reactstrap components
import { Container, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
// core components
import { AuthFooter, AuthNavbar } from "../components";
import PageNotFound from "./../views/PageNotFound";

import routes from "../routes";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }

  getRoutes = (routes) => {
    let routesMap = routes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
    routesMap.push(<Route component={PageNotFound} key={routesMap.length} />);
    return routesMap;
  };
  render() {
    const { jwt } = this.props;

    const showAuthLayout = (
      <>
        <div className="main-content">
          <AuthNavbar />
          <div className="header py-7">
            <div className="separator separator-bottom separator-skew zindex-100" />
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-3">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
        <ToastContainer />
      </>
    );

    const adminRedirect = <Redirect to="/" />;

    return jwt ? adminRedirect : showAuthLayout;
  }
}

const mapStateToProps = ({ auth }) => ({
  jwt: auth.jwt,
});

export default connect(mapStateToProps)(Auth);
