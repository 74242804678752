import React from "react";
import { Row } from "reactstrap";
import { FormInput } from "./../../components";

const UsersFormFields = ({ mode, nameGroup }) => (
  <>
    <Row>
      <FormInput
        label="Nome"
        name={nameGroup === undefined ? "name" : `${nameGroup}.name`}
        lg={6}
        sm={6}
      />
      <FormInput
        label="E-mail"
        name={nameGroup === undefined ? "email" : `${nameGroup}.email`}
        lg={6}
        sm={6}
      />
    </Row>
    {mode === "add" && (
      <Row>
        <FormInput
          label="Senha"
          name={nameGroup === undefined ? "password" : `${nameGroup}.password`}
          lg={6}
          sm={6}
          type="password"
        />
        <FormInput
          label="Confirme a senha"
          name={
            nameGroup === undefined
              ? "repeat_password"
              : `${nameGroup}.repeat_password`
          }
          lg={6}
          sm={6}
          type="password"
        />
      </Row>
    )}
  </>
);

export default UsersFormFields;
