import React, { useState } from "react";

import { Col, CustomInput, Row } from "reactstrap";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer
} from "@react-google-maps/api";

const ClientsLocations = props => {
  const [hasFurniture, setHasFurniture] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = event => {
    const isChecked = event.target.checked;
    setHasFurniture(isChecked);
    setLoading(true);
    props.onChange(isChecked).then(() => {
      setLoading(false);
    });
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col xs={12} sm={6} className="mt-5 mb-4">
            <h2 className="text-white">Mapa de clientes</h2>
          </Col>
          <Col xs={12} sm={6} className="mt-5 mb-4 text-right">
            <CustomInput
              type="switch"
              id="has_furniture"
              name="has_furniture"
              label="Apenas com móveis Bontempo"
              onChange={onChange}
              checked={hasFurniture}
            />
          </Col>
        </Row>

        <div
          className="rounded bg-secondary"
          style={{ height: 500, width: "100%" }}
        >
          {!loading ? (
            <LoadScript
              id="script-loader"
              googleMapsApiKey="AIzaSyBZKaZnBJ_S7m5lJWCLlLYCsVnmg29AX9M"
            >
              <GoogleMap
                mapContainerClassName="rounded"
                mapContainerStyle={{
                  height: 500,
                  width: "100%"
                }}
                onLoad={map => {
                  const bounds = new window.google.maps.LatLngBounds();
                  props.clientsLocations.forEach((marker, i) => {
                    bounds.extend({
                      lat: parseFloat(marker.x),
                      lng: parseFloat(marker.y)
                    });
                  });
                  map.fitBounds(bounds);
                }}
              >
                <MarkerClusterer
                  averageCenter
                  enableRetinaIcons
                  styles={[
                    {
                      fontFamily: "Montserrat",
                      textColor: "white",
                      textSize: 16,
                      url:
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/" +
                        "cluster-bg.png",
                      height: 30,
                      width: 30
                    },
                    {
                      fontFamily: "Montserrat",
                      textColor: "white",
                      textSize: 16,
                      url:
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/" +
                        "cluster-bg.png",
                      height: 36,
                      width: 36
                    },
                    {
                      fontFamily: "Montserrat",
                      textColor: "white",
                      textSize: 16,
                      url:
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/" +
                        "cluster-bg.png",
                      height: 42,
                      width: 42
                    },
                    {
                      fontFamily: "Montserrat",
                      textColor: "white",
                      textSize: 16,
                      url:
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/" +
                        "cluster-bg.png",
                      height: 54,
                      width: 54
                    },
                    {
                      fontFamily: "Montserrat",
                      textColor: "white",
                      textSize: 16,
                      url:
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/" +
                        "cluster-bg.png",
                      height: 60,
                      width: 60
                    }
                  ]}
                  gridSize={2}
                  minimumClusterSize={2}
                  maxZoom={15}
                >
                  {clusterer =>
                    props.clientsLocations.map((marker, i) => (
                      <Marker
                        clusterer={clusterer}
                        position={{
                          lat: parseFloat(marker.x),
                          lng: parseFloat(marker.y)
                        }}
                        key={"marker" + i}
                      />
                    ))
                  }
                </MarkerClusterer>
              </GoogleMap>
            </LoadScript>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default ClientsLocations;
