import React from "react";
import { Container } from "reactstrap";
import _List from "./../_List";

import { connect } from "react-redux";
import { get, remove } from "./../../store/ducks/clothingColors";

import { ContentTable, Header } from "./../../components";

class ClothingColorsList extends _List {
  render() {
    const { data } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--7 bg-default" fluid>
          <ContentTable
            columns={[
              {
                title: "Título",
                slug: "title"
              },
              {
                title: "Cor",
                slug: "colorPreview"
              }
            ]}
            data={data}
            hasInsert={true}
            middleware={row => {
              row.colorPreview = (
                <div
                  style={{
                    backgroundColor: row.color,
                    borderColor: "#999999",
                    borderRadius: "50%",
                    borderStyle: "solid",
                    borderWidth: 1,
                    height: 30,
                    width: 30
                  }}
                />
              );

              return row;
            }}
            onDelete={this.props.remove}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, clothingColors }) => ({
  currentRoute: common.currentRoute,
  data: clothingColors.data,
  loading: clothingColors.loading,
  loadingBar: common.loadingBar
});

const mapDispatchToProps = {
  get,
  remove
};

export default connect(mapStateToProps, mapDispatchToProps)(ClothingColorsList);
