import React, { useState } from "react";
import { Col } from "reactstrap";
import MaskedInput from "react-text-mask";
import _ from "lodash";

import { Input } from "reactstrap";

import FormField from "./FormField";

function FormMaskedComponent({
  mask,
  field,
  form,
  transformIn,
  transformOut,
  ...props
}) {
  function _transformIn(value) {
    return typeof transformIn === "function" ? transformIn(value) : value;
  }

  function _transformOut(value) {
    return typeof transformOut === "function" ? transformOut(value) : value;
  }

  // Caso o valor esteja em outro nível do objeto, utiliza o lodash para procurar
  const initialValue = props.property
    ? _.get(form.initialValues, props.property)
    : field.value;
  const [fieldValue, setFieldValue] = useState(_transformIn(initialValue));
  const fieldName = props.property || field.name;

  if (initialValue && !fieldValue) {
    form.setFieldValue(fieldName, initialValue);
    setFieldValue(initialValue);

    if (props.onChange) {
      props.onChange(initialValue, form);
    }
  }

  if (props.value && fieldValue !== props.value) {
    form.setFieldValue(fieldName, props.value);
    setFieldValue(props.value);

    if (props.onChange) {
      props.onChange(props.value, form);
    }
  }

  return (
    <Col xs={12} className={props.noPadding ? 'p-0' : '' }>
      <MaskedInput
        autoComplete="off"
        {...props}
        guide={true}
        id={field.name}
        invalid={Boolean(
          _.get(form.touched, fieldName) && _.get(form.errors, fieldName)
        )}
        mask={mask}
        name={field.name}
        onBlur={event => {
          if (props.onBlur) {
            props.onBlur(event, form);
          }

          form.setFieldTouched(field.name, true);
          if (event.target.value.match(/_/)) {
            form.setFieldValue(field.name, "");
            setFieldValue("");
          }
        }}
        onChange={event => {
          const newValue = _transformOut(event.target.value);

          if (props.onChange) {
            props.onChange(newValue, form);
          }
          form.setFieldValue(field.name, newValue);
          setFieldValue(newValue);
        }}
        render={(ref, inputProps) => <Input innerRef={ref} {...inputProps} />}
        type="text"
        value={fieldValue}
      />
    </Col>
  );
}

export default function FormMasked(props) {
  return <FormField {...props} component={FormMaskedComponent} />;
}
