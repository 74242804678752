import React from "react";

import { Col } from "reactstrap";

import {
  Bar,
  BarChart as BarRechart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BarChart = (props) => (
  <Col xs={12} sm={6} className="my-5">
    <h2 className="text-white">{props.title}</h2>
    <div
      className="bg-secondary p-3 rounded d-flex justify-content-start align-items-center"
      style={{ height: "100%" }}
    >
      <ResponsiveContainer width="100%" aspect={2}>
        <BarRechart
          data={props.data.map((item) => {
            return {
              name: item.title,
              Total: Math.round(
                ((item.total || 0) * 100) / props.totalNewClients
              ),
            };
          })}
        >
          <XAxis dataKey="name" />
          <YAxis
            tickFormatter={(decimal) => {
              return `${decimal}%`;
            }}
          />
          <Tooltip
            cursor={false}
            formatter={(decimal) => {
              return `${decimal}%`;
            }}
          />
          <Bar
            background={false}
            dataKey="Total"
            fill={props.color}
            maxBarSize={35}
            strokeWidth={0}
            type="monotone"
          />
        </BarRechart>
      </ResponsiveContainer>
    </div>
  </Col>
);

export default BarChart;
