import React from "react";

// reactstrap components
import {
  Col
} from "reactstrap";

class PageNotFound extends React.Component {
  render() {
    return (
      <>
        <Col lg="5" md="7">
        </Col>
      </>
    );
  }
}

export default PageNotFound;
