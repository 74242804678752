import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, NavItem } from "reactstrap";
import _List from "./../_List";

import { connect } from "react-redux";
import { get, remove } from "./../../store/ducks/users";

import { ContentTable, Header } from "./../../components";

class UsersList extends _List {
  render() {
    const { currentRoute, data, loading } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--7 bg-default" fluid>
          <ContentTable
            columns={[
              {
                title: "Nome",
                slug: "name"
              },
              {
                title: "E-mail",
                slug: "email"
              }
            ]}
            customButtons={row => (
              <NavItem className="pl-2">
                <Link
                  to={
                    currentRoute.layout + currentRoute.path + "/senha/" + row.id
                  }
                >
                  <Button color="default" size="sm" type="button">
                    <i className="fa fa-lock" /> Alterar senha
                  </Button>
                </Link>
              </NavItem>
            )}
            data={data}
            hasInsert={true}
            loading={loading}
            onDelete={this.props.remove}
          />
        </Container>
      </>
    );
  }
}

const mapStateToProps = ({ common, users }) => ({
  currentRoute: common.currentRoute,
  data: users.data,
  loading: users.loading,
  loadingBar: common.loadingBar
});

const mapDispatchToProps = {
  get,
  remove
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
